import React from 'react';
import SaboteurResultBar from './SaboteurResultBar';

const AssessmentResult = ({result, className}) => (
  <div className={className}>
    {result.map(({score, saboteur}) => (
      <SaboteurResultBar key={saboteur} saboteur={saboteur} score={score} />
    ))}
  </div>
);

export default AssessmentResult;
