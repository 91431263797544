import React from 'react';

const Loading = ({opacity = 0.5, fixed = false}) => (
  <div
    className={`${
      fixed ? 'fixed' : 'absolute'
    } top-0 right-0 w-full h-full bg-white text-center z-max opacity-${(1 - opacity) * 100}`}
  >
    <div className='pi-loading-icon'>
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

export default Loading;
