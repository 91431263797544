import {observable, computed, action, transaction, autorun} from 'mobx';
import axios from 'axios';
import store from 'store';

const STORE_KEY = 'pod_store';
const baseUrl = `${process.env.REACT_APP_API_URL}/groups`;

export default class PodStore {
  @observable submitted = false;
  @observable secret = '';
  @observable leader = '';
  @observable podToEdit = {
    pending: false,
    data: {}
  };

  constructor() {
    this.fromJson(store.get(STORE_KEY) || {});

    autorun(() => {
      store.set(STORE_KEY, this.asJson);
    });
  }

  @computed get asJson() {
    return {
      submitted: this.submitted
    };
  }

  fromJson(json) {
    this.submitted = !!json.submitted;
  }

  @action async submit(values) {
    await axios.post(`${baseUrl}/${this.secret}/pods`, values);

    this.submitted = true;
  }

  @action async update(id, values) {
    await axios.post(`${baseUrl}/${this.secret}/pods/${id}`, values);

    this.submitted = true;
  }

  @action loadToEdit = () => {
    this.podToEdit.pending = true;

    return axios
      .get(`${baseUrl}/${this.secret}/pods`, {params: {leader: this.leader}})
      .then(({data}) =>
        transaction(() => {
          this.podToEdit.data = data;
          this.podToEdit.pending = false;
        })
      );
  };

  @action checkEmail = (email) => {
    return axios
      .get(`${baseUrl}/${this.secret}/pods/emails`, {params: {email, leader: this.leader}})
      .then(({data}) => data);
  };

  @action checkTitle = (title) => {
    return axios
      .get(`${baseUrl}/${this.secret}/pods/titles`, {params: {title, leader: this.leader}})
      .then(({data}) => data);
  };
}
