import React, {useCallback} from 'react';
import {useActionOnEnterKeyDownHandler} from '../../utils/useActionOnEnterKeyDownHandler';
import {usePreventFocusOnClick} from '../../utils/usePreventFocusOnClick';

const QuestionButton = ({active, answer, onAnswer}) => {
  const handleAnswer = useCallback(() => {
    onAnswer && onAnswer(answer);
  }, [answer, onAnswer]);

  const handlePreventFocusOnClick = usePreventFocusOnClick();

  return (
    <div
      className='h-23 w-23 border-2 rounded-full border-solid border-black float-left cursor-pointer'
      onClick={handleAnswer}
      onMouseDown={handlePreventFocusOnClick}
      onKeyDown={useActionOnEnterKeyDownHandler(handleAnswer)}
    >
      <div
        className={`focus:outline-blue rounded-full w-full h-full ${active ? 'bg-black' : ''}`}
      />
    </div>
  );
};

export default QuestionButton;
