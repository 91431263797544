import React, {useCallback, useEffect, useState} from 'react';
import PhoneInput from 'react-phone-number-input';
import PhoneWithoutCountryInput from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css';

const Input = React.forwardRef((props, ref) => (
  <input
    ref={ref}
    {...props}
    className='w-full z-1 pl-4 small-type outline-none bg-white placeholder-black-30'
  />
));

const Phone = ({name, onChange, ...rest}) => {
  const [flags, setFlags] = useState();
  useEffect(() => {
    let unmounted = false;

    const loadFlags = async () => {
      const flags = (await import('react-phone-number-input/flags')).default;
      !unmounted && setFlags(flags);
    };
    loadFlags().then();

    return () => (unmounted = true);
  }, []);

  const handleChange = useCallback(
    (value) => {
      onChange({target: {name: 'phone', value}});
    },
    [onChange]
  );

  const Component = flags ? PhoneInput : PhoneWithoutCountryInput;

  return (
    <Component flags={flags} name={name} inputComponent={Input} onChange={handleChange} {...rest} />
  );
};

export default Phone;
