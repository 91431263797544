import Container from './Container';
import React from 'react';
import AssessmentBlankPage from './AssessmentBlankPage';

const AssessmentWideCenteredPage = ({children}) => (
  <AssessmentBlankPage>
    <div className='flex-1 flex flex-col justify-center items-center'>
      <Container className='flex-initial w-full max-w-600 lg:max-w-910 py-20 lg:py-40'>
        {children}
      </Container>
    </div>
  </AssessmentBlankPage>
);

export default AssessmentWideCenteredPage;
