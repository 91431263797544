import 'raf/polyfill';
import 'babel-polyfill';
import './raven';
import React from 'react';
import ReactDOM from 'react-dom';
import Root from './components/Root';
import './index.css';
import './typography.css';
import './images.css';

const render = (AppRoot) => {
  ReactDOM.render(<AppRoot />, document.getElementById('app'));
};

render(Root);
