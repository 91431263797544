import React, {forwardRef, useCallback, useEffect, useRef} from 'react';
import {inject, observer} from 'mobx-react';
import findIndex from 'lodash/findIndex';
import LoadingPage from '../LoadingPage';
import Button from '../Page/Button';
import gaService from '../../ga.service';
import chevronRight from '../../img/chevronRight.svg';
import AssessmentFixedFooterPage from '../Page/AssessmentFixedFooterPage';
import SaboteurImage from '../Page/SaboteurImage';
import saboteurs from '../../const/saboteurs';
import ProgressBar from './AssessmentResult/ProgressBar';
import saboteurDetails from '../../const/saboteurDetails.json';
import Container from '../Page/Container';
import Footer from '../Page/Footer';

const Selector = forwardRef(({children}, ref) => (
  <div
    ref={ref}
    className='overflow-x-auto overflow-y-hidden text-left whitespace-nowrap pt-7 px-30 pb-22 lg:pb-7'
  >
    {children}
  </div>
));

const SelectorItem = ({children, active, className = '', ...rest}) => (
  <div
    className={`inline-block text-center h-97 w-86 mr-8 rounded-8 hover:bg-grey-background ${
      active ? 'bg-grey-background' : 'cursor-pointer'
    } ${className}`}
    {...rest}
  >
    {children}
  </div>
);

const AssessmentResultDetailsPage = ({
  history,
  sabStore,
  match: {
    params: {resultId, saboteur}
  }
}) => {
  useEffect(() => {
    sabStore.loadAssessmentResults(resultId);
  }, [resultId, sabStore]);

  useEffect(() => {
    const {formattedResult = []} = sabStore.result;

    const index = saboteur
      ? findIndex(formattedResult, (result) => result.saboteur === saboteur)
      : 0;

    gaService.sendEvent('Saboteur Assessment', 'click', `sab_results_${index + 1}`);
  }, [sabStore, saboteur]);

  const result = sabStore.result;
  const {pending, formattedResult} = result;

  const allSaboteurs = formattedResult.map((r) => r.saboteur);
  const index = saboteur ? findIndex(allSaboteurs, (s) => s === saboteur) : 0;
  const nextSaboteur = allSaboteurs[index + 1];
  const selectedSaboteur =
    saboteur && allSaboteurs.includes(saboteur) ? saboteur : formattedResult[0].saboteur;
  const selectedSaboteurScore = formattedResult.find((r) => r.saboteur === selectedSaboteur).score;
  const details = saboteurDetails.find((d) => d.name === selectedSaboteur);

  const handleNextSaboteurClick = useCallback(() => {
    history.replace(`/saboteur/results/${resultId}/saboteurs/${nextSaboteur}`);
  }, [history, nextSaboteur, resultId]);

  if (pending) {
    return <LoadingPage />;
  }

  const handleMoveToResultsMorePage = () => {
    history.push(`/saboteur/results/${resultId}/more`);
  };

  return (
    <AssessmentFixedFooterPage
      footer={
        <DetailsFooter
          result={formattedResult}
          selectedSaboteur={selectedSaboteur}
          onChange={(saboteur) =>
            history.replace(`/saboteur/results/${resultId}/saboteurs/${saboteur}`)
          }
        />
      }
    >
      <div key={saboteur} className='pb-126 flex flex-row justify-center items-center'>
        <Container className='flex-1 max-w-910'>
          <div className='body mt-50'>You can switch between saboteurs in the bottom menu</div>
          <div className='heading-3 mt-16'>{saboteurs[selectedSaboteur]}</div>
          <ProgressBar
            className='mt-16'
            position={selectedSaboteurScore / 10}
            value={selectedSaboteurScore}
          />
          <div className='body lg:body-lg mt-21'>{details.description}</div>

          {details.info.map((info) => (
            <div className='mt-48' key={info.name}>
              <div className='heading-4 mb-16'>{info.display_name}</div>
              {info.data.length === 1 ? (
                info.data[0]
              ) : (
                <ul className='body list-disc ml-26'>
                  {info.data.map((data, i) => (
                    <li key={i}>{data}</li>
                  ))}
                </ul>
              )}
            </div>
          ))}
          <div className='mt-48 flex flex-row justify-between'>
            <Button
              small
              white
              label={
                <div className='flex flex-row items-center'>
                  <img alt='next' src={chevronRight} className='h-18 w-18 mr-4' />
                  Next Saboteur
                </div>
              }
              className='mr-8'
              disabled={!nextSaboteur}
              tabIndex={1}
              onEnter={handleNextSaboteurClick}
              onClick={handleNextSaboteurClick}
            />
            <Button
              small
              label='Finish Exploring'
              className='ml-8'
              tabIndex={0}
              onClick={handleMoveToResultsMorePage}
              onEnter={handleMoveToResultsMorePage}
            />
          </div>
          <Footer className='mt-48 mb-40 text-black-57' />
        </Container>
      </div>
    </AssessmentFixedFooterPage>
  );
};

const DetailsFooter = ({result, selectedSaboteur, onChange}) => {
  const selector = useRef();

  useEffect(() => {
    if (selector.current?.querySelector) {
      const sabElement = selector.current.querySelector(`#${selectedSaboteur}`);
      const leftPos = sabElement.offsetLeft;
      const containerWidth = selector.current.getBoundingClientRect().width;
      const elementWidth = sabElement.getBoundingClientRect().width;
      const toScroll = leftPos + elementWidth / 2 - containerWidth / 2;
      selector.current.scrollLeft = Math.max(toScroll, 0);
    }
  }, [selectedSaboteur]);

  return (
    <div className='bg-white border-t border-black-lines flex justify-center items-center'>
      <Selector ref={selector}>
        {result.map(({saboteur}) => (
          <SelectorItem
            onClick={() => onChange(saboteur)}
            key={saboteur}
            id={saboteur}
            active={saboteur === selectedSaboteur}
          >
            <SaboteurImage saboteur={saboteur} className='w-60 h-60 mt-12 inline-block' />
            <div className='text-11 font-matter -mt-2'>{saboteurs[saboteur]}</div>
          </SelectorItem>
        ))}
      </Selector>
    </div>
  );
};

export default inject('sabStore')(observer(AssessmentResultDetailsPage));
