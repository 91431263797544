import React from 'react';
import store from 'store';
import {OPTIN_STORE_KEY} from './OptInForm';
import LoadingPage from './LoadingPage';

const OptedInPage = () => {
  (window.top || window.parent)?.postMessage('opted_in', '*');

  const optinData = store.get(OPTIN_STORE_KEY);

  store.remove(OPTIN_STORE_KEY);

  if (optinData?.backUrl) {
    window.location.href = optinData.backUrl;
  }

  return <LoadingPage />;
};

export default OptedInPage;
