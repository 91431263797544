import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import AssessmentResult from './AssessmentResult/AssessmentResult';
import LoadingPage from '../LoadingPage';
import Button from '../Page/Button';
import AssessmentWideCenteredPage from '../Page/AssessmentWideCenteredPage';

class AssessmentResultScorePage extends Component {
  UNSAFE_componentWillMount() {
    const {
      sabStore,
      match: {
        params: {resultId}
      }
    } = this.props;

    sabStore.loadAssessmentResults(resultId);
  }

  render() {
    const {
      sabStore,
      history,
      match: {
        params: {resultId}
      }
    } = this.props;
    const {pending, formattedResult} = sabStore.result;

    if (pending) {
      return <LoadingPage />;
    }

    const handleMoveToResultsSaboteursPage = () => {
      history.push(`/saboteur/results/${resultId}/saboteurs`);
    };

    return (
      <AssessmentWideCenteredPage>
        <h3 className='heading-4 lg:heading-3'>
          {sabStore.result.data.first_name}, here are your results
        </h3>
        <AssessmentResult className='mt-20 lg:mt-40' result={formattedResult} />
        <div className='mt-32'>
          Explore saboteurs’ descriptions and notice the ones that speak to you. Remember, no one
          fits any Saboteur description completely. Look for the general patterns.
        </div>
        <Button
          label='Explore'
          tabIndex={0}
          onClick={handleMoveToResultsSaboteursPage}
          onEnter={handleMoveToResultsSaboteursPage}
          className='mt-16'
        />
      </AssessmentWideCenteredPage>
    );
  }
}

export default inject('sabStore')(observer(AssessmentResultScorePage));
