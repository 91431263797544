import React from 'react';
import {observer} from 'mobx-react';
import Button from './Page/Button';
import Input from './Form/Input';
import FormError from './Form/FormError';
import {Formik} from 'formik';
import Loading from './Loading';
import Container from './Page/Container';
import AssessmentBlankPage from './Page/AssessmentBlankPage';
import Link from './Link';
import {useActionOnEnterKeyDownHandler} from '../utils/useActionOnEnterKeyDownHandler';
import {usePreventFocusOnClick} from '../utils/usePreventFocusOnClick';

const ConfirmResultsForm = (props) => {
  const handleSubmitForm = (values, actions) => {
    const {onConfirm} = props;

    return onConfirm(values.code).catch((err) => {
      if (err.response?.data?.type === 'too_often') {
        actions.setErrors({
          form: 'Too many requests registered.\nPlease try again in few minutes.'
        });
      } else if (err.response?.data?.message) {
        actions.setErrors({form: err.response.data.message});
      } else {
        throw err;
      }
    });
  };

  const validateForm = (values) => {
    const errors = {};

    if (!values.code) {
      errors.code = 'Code is required';
    }

    return errors;
  };

  const {updateLink, email, history, code} = props;
  const handleMoveToUpdateLink = () => {
    history.push(updateLink);
  };

  const handlePreventFocusOnClick = usePreventFocusOnClick();

  return (
    <AssessmentBlankPage>
      <div className='flex-1 flex flex-col justify-center items-center'>
        <Container className='flex-initial max-w-460 lg:max-w-600 py-50'>
          <h3 className='heading-4 lg:heading-3'>We need to confirm your email address</h3>
          <div className='body pt-21 lg:pt-40'>
            <p>
              Please enter the confirmation code we just sent to your email address{' '}
              <strong>{email}</strong>
            </p>
            <p>
              If we have the wrong email address please{' '}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                onKeyDown={useActionOnEnterKeyDownHandler(handleMoveToUpdateLink)}
                onMouseDown={handlePreventFocusOnClick}
                onClick={handleMoveToUpdateLink}
              >
                {' '}
                update here
              </Link>
            </p>
          </div>
          <div className='mt-30'>
            <Formik initialValues={{code}} validate={validateForm} onSubmit={handleSubmitForm}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                handleBlur,
                submitForm,
                isSubmitting
              }) => (
                <form onSubmit={handleSubmit}>
                  {isSubmitting ? <Loading /> : null}
                  <Input
                    className='w-full'
                    type='text'
                    name='code'
                    placeholder='Confirmation Code'
                    error={errors.code && touched.code && errors.code}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.code}
                  />
                  {errors.form ? <FormError className='mt-30'>{errors.form}</FormError> : null}
                  <Button
                    className='mt-35'
                    label='Submit'
                    tabIndex={0}
                    onClick={submitForm}
                    onEnter={submitForm}
                    disabled={isSubmitting}
                  />
                </form>
              )}
            </Formik>
          </div>
        </Container>
      </div>
    </AssessmentBlankPage>
  );
};

export default observer(ConfirmResultsForm);
