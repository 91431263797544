import React from 'react';
import Link from '../Link';
import LinkButton from '../Page/LinkButton';
import {inject, observer} from 'mobx-react';
import AssessmentTextPage from '../Page/AssessmentTextPage';

const ApplicationSubmittedPage = ({applicationStore, history}) => {
  if (!applicationStore.submitted) {
    history.push('/application');
  }

  return (
    <AssessmentTextPage>
      <div className='heading-4 lg:heading-3'>Congratulations!</div>
      <div className='body mt-40'>
        <p>
          Your application for the PQ Program grant has been received and is now pending review.
          We’ll be in touch within 3-5 business days to let you know whether you’ve been approved.
          Be sure to keep an eye on your inbox.
        </p>

        <div className='heading-4 my-30'>One last thing before you go...</div>

        <p>
          Per the eligibility requirements, you’ll need to select an accountability group of 2-4
          other coaches in your network.
        </p>

        <p>
          Since <strong>all</strong> coaches must complete this application to be considered (even
          those you select for your group), make sure you pass along the application! (No need to
          tell us who they are yet -- you’ll be able to identify them after your application has
          been approved.)
        </p>

        <div className='text-center mt-30'>
          <LinkButton
            label='Share via LinkedIn'
            href='https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fwww.linkedin.com%2Fpulse%2Fmy-multi-million-dollar-grant-coaches-shirzad-chamine'
            target='_blank'
          />
          <br />
          <LinkButton
            className='mt-15'
            label='Share via Email'
            href="mailto:?cc=support@positiveintelligence.com&subject=Check%20out%20this%20free%20and%20powerful%20program%20for%20coaches!&body=Hey%2C%0D%0A%0D%0AI%20just%20applied%20for%20a%20powerful%20coaching%20program%20that's%20being%20offered%20by%20Shirzad%20Chamine.%20If%20you%20aren%E2%80%99t%20familiar%20with%20him%2C%20he%E2%80%99s%20the%20author%20of%20the%20New%20York%20Times%20best-selling%20book%2C%20Positive%20Intelligence%2C%20and%20a%20Stanford%20lecturer.%20He's%20taken%20his%20breakthrough%20research%20in%20neuroscience%20and%20the%20data%20he%E2%80%99s%20collected%20from%20over%20500%2C000%20people%20to%20create%20a%207-week%20program%20that%20helps%20people%20rewire%20their%20brain%20for%20more%20happiness%20and%20success.%0D%0A%0D%0AThe%20program%20is%20normally%20sold%20for%20%241%2C000%2C%20but%20he%20recently%20announced%20that%20he's%20now%20giving%20the%20entire%20program%20away%20for%20FREE%20to%20coaches%20who%20are%20looking%20to%20significantly%20increase%20their%20impact.%0D%0A%0D%0ASince%20you're%20a%20fellow%20coach%2C%20I%20immediately%20thought%20of%20you.%20You%20can%20wread%20all%20about%20the%20opportunity%20and%20how%20to%20apply%20here%3A%20https%3A%2F%2Fwww.linkedin.com%2Fpulse%2Fmy-multi-million-dollar-grant-coaches-shirzad-chamine%2F%0D%0A%0D%0AI%20thought%20it%20would%20be%20really%20great%20to%20go%20through%20the%20program%20together%20so%20we%20can%20support%20each%20other%20and%20provide%20accountability.%20Are%20you%20interested%3F%20Let%20me%20know%20what%20you%20think%20and%20if%20you%20end%20up%20applying!%0D%0A%0D%0ATalk%20soon%2C%0D%0A"
          />
        </div>

        <div className='mt-30'>
          Questions? Contact us at{' '}
          <Link href='mailto:support@positiveintelligence.com'>
            support@positiveintelligence.com
          </Link>
          .
        </div>

        <div>Thanks again for your interest in the PQ Program.</div>

        <div>Coach Shirzad</div>
      </div>
    </AssessmentTextPage>
  );
};

export default inject('applicationStore')(observer(ApplicationSubmittedPage));
