import {useCallback} from 'react';

export const useActionOnEnterKeyDownHandler = (actionToCall) => {
  return useCallback(
    (e) => {
      if (e.key === 'Enter') {
        actionToCall();
      }
    },
    [actionToCall]
  );
};
