import React from 'react';
import Button from '../Page/Button';
import gaService from '../../ga.service';
import AssessmentInfoPage from '../Page/AssessmentInfoPage';

const OverviewPage = ({history}) => {
  const handleMoveToInstructionsPage = () => {
    gaService.sendEvent('PQ Assessment', 'click', 'pq_to_instructions');
    history.push(`/pq/instructions`);
  };

  return (
    <AssessmentInfoPage
      image={<div className='img-pq-definition' />}
      title='Definition'
      text={
        <>
          <p>PQ is the measure of your mental fitness.</p>
          <p>
            If you’re <strong>physically fit,</strong> you can climb steep hills without{' '}
            <strong>physical stress.</strong> Similarly, If you’re <strong>mentally fit,</strong>{' '}
            you can handle life’s great challenges without <strong>mental stress,</strong>{' '}
            frustration, other negative emotions.
          </p>
          <p>
            PQ reveals your mental fitness level by measuring the strength of your{' '}
            <strong>positive mental muscles versus the negative.</strong>
          </p>
        </>
      }
      button={
        <Button
          label='Assess PQ Score'
          tabIndex={0}
          onClick={handleMoveToInstructionsPage}
          onEnter={handleMoveToInstructionsPage}
        />
      }
    />
  );
};

export default OverviewPage;
