/* globals ga */

const gaService = {
  init() {
    if (!process.env.REACT_APP_GA_CODE) {
      return;
    }

    /* eslint-disable */
    (function (i, s, o, g, r, a, m) {
      i['GoogleAnalyticsObject'] = r;
      (i[r] =
        i[r] ||
        function () {
          (i[r].q = i[r].q || []).push(arguments);
        }),
        (i[r].l = 1 * new Date());
      (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');
    /* eslint-enable */

    return ga('create', process.env.REACT_APP_GA_CODE, 'auto');
  },

  pageView(path) {
    if (typeof ga === 'function') {
      ga('set', 'page', path);
      ga('send', 'pageview');
    } else {
      console.log('GA pageview', {path}); // eslint-disable-line
    }
  },

  sendEvent(eventCategory, eventAction, eventLabel, hitCallback) {
    if (typeof ga === 'function') {
      return ga('send', {
        hitType: 'event',
        eventCategory,
        eventAction,
        eventLabel,
        hitCallback
      });
    } else {
      console.log('GA event', {eventCategory, eventAction, eventLabel}); // eslint-disable-line
    }

    return hitCallback && hitCallback();
  }
};

gaService.init();

export default gaService;
