import React, {useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import LoadingPage from '../LoadingPage';
import Button from '../Page/Button';
import AssessmentInfoPage from '../Page/AssessmentInfoPage';
import Vortex from './Vortex';

const AssessmentResultTippingPointPage = ({
  pqStore,
  history,
  match: {
    params: {resultId}
  }
}) => {
  useEffect(() => {
    pqStore.loadAssessmentResults(resultId);
  }, [pqStore, resultId]);

  const {pending, data} = pqStore.result;
  const pq = Math.round(data.pq);

  if (pending) {
    return <LoadingPage />;
  }

  const handleMoveToPqResultsMeaningPage = () => {
    history.push(`/pq/results/${resultId}/meaning`);
  };

  return (
    <AssessmentInfoPage
      image={<Vortex score={pq} withPoints />}
      title='Why 75 as Tipping Point'
      text={
        <>
          <p>
            Your brain amplifies negative versus positive energy by a minimum factor of about 3 to
            1. This means <strong>it takes at least 3 positives to counteract one negative</strong>{' '}
            and experience the net-positive effect. That occurs at the tipping-point PQ Score.
          </p>
        </>
      }
      button={
        <Button
          label='What Your Score Reveals'
          tabIndex={0}
          onClick={handleMoveToPqResultsMeaningPage}
          onEnter={handleMoveToPqResultsMeaningPage}
        />
      }
    />
  );
};

export default inject('pqStore')(observer(AssessmentResultTippingPointPage));
