import React, {useMemo} from 'react';

const CONTAINER_SIZE = 90;

const Mask = ({side}) => (
  <div
    className={`h-1/2 w-1/2 bg-white absolute z-2 ${
      side === 0 || side === 1 ? 'top-0' : 'top-1/2'
    } ${side === 0 || side === 2 ? 'left-0' : 'left-1/2'}`}
  />
);

const Score = ({children}) => (
  <div
    className='absolute top-1/2 left-0 transform -translate-y-1/2 text-center w-full text-28 font-matter'
    children={children}
  />
);

const Circle = ({size, side, offset, color, rotationSpeed, blinkSpeed}) => (
  <div
    style={{
      width: size,
      height: size,
      top: offset,
      left: offset,
      animationDuration: rotationSpeed + 's'
    }}
    className='rounded-2/5 overflow-hidden absolute animate-pq-spin'
  >
    <div
      className='w-full h-full rounded-full border-solid border-3 absolute top-0 left-0 z-1 animate-pq-pulse'
      style={{animationDuration: blinkSpeed + 's', borderColor: '#' + color}}
    />
    <Mask side={side} />
  </div>
);

const PQScore = ({score}) => {
  const circles = useMemo(
    () =>
      [90, 74, 58].map((size, i) => ({
        size,
        side: i,
        offset: (CONTAINER_SIZE - size) / 2,
        color: getColors(score)[i],
        // 7 5 3 etc
        rotationSpeed: 7 - 2 * i,
        // from 9 to 18
        blinkSpeed: Math.round(Math.random() * 9 + 9)
      })),
    [score]
  );

  return (
    <div className='relative' style={{width: CONTAINER_SIZE, height: CONTAINER_SIZE}}>
      {circles.map((circle) => (
        <Circle
          key={circle.size}
          size={circle.size}
          offset={circle.offset}
          color={circle.color}
          rotationSpeed={circle.rotationSpeed}
          blinkSpeed={circle.blinkSpeed}
          side={circle.side}
        />
      ))}
      <Score>{score}</Score>
    </div>
  );
};

const getColors = (score) => {
  if (score >= 90) {
    return ['12DA99', '109452', '447C25'];
  }

  if (score >= 80) {
    return ['A2DA12', '439410', '4E7C25'];
  }

  if (score >= 71) {
    return ['D0DA12', '889410', '7C6825'];
  }

  if (score >= 50) {
    return ['DA9D12', '949110', '7C6C25'];
  }

  if (score >= 25) {
    return ['DA5B12', '945D10', '7C6B25'];
  }

  return ['DA1212', '941010', '7C3F25'];
};

export default PQScore;
