import saboteursImg from '../../img/shareContentImages/saboteurs.png';
import React from 'react';
import ContentContainer from './ContentContainer';

const B2CContent = ({clientName, clientType}) => (
  <ContentContainer
    className='bg-orange min-w-390'
    title='We all have Saboteurs'
    description='Share the link with your friends to see if you have any in common'
    clientName={clientName}
    clientType={clientType}
    refId='a-cn'
    withoutLearnMoreButton
  >
    <div className='w-140 h-140 sm:w-300 sm:h-300 mt-24 sm:mt-40 sm:self-center'>
      <img className='w-full h-full' src={saboteursImg} alt='' />
    </div>
  </ContentContainer>
);

export default B2CContent;
