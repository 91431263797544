import PQLogo from '../PQLogo';
import React from 'react';
import LinkButton from '../Page/LinkButton';
import ShareButton from './ShareButton';
import gaService from '../../ga.service';

const ContentContainer = ({
  className,
  title,
  description,
  withoutLearnMoreButton = false,
  withoutShareButton = false,
  clientName,
  clientType,
  refId,
  children
}) => (
  <div
    className={`flex-initial w-full lg:w-min lg:max-w-600 flex flex-col p-30 sm:py-100 sm:px-50 ${className}`}
  >
    <div className='block sm:hidden'>
      <PQLogo />
    </div>
    <div className='flex-auto flex-col flex items-start pt-24 sm:py-85 justify-center'>
      <h3 className='heading-6 sm:heading-3'>{title}</h3>
      <h4 className='small-type-medium sm:heading-4 sm:opacity-60 pt-16'>{description}</h4>
      {children}
      <div className='pt-24 sm:pt-40 w-full'>
        {!withoutShareButton && <ShareButton {...{clientName, clientType, refId}} />}
        {!withoutLearnMoreButton && (
          <LinkButton
            label='Learn More'
            href={`${process.env.REACT_APP_PQ_FOR_ORGANIZATIONS_URL}?ref=${refId}`}
            target='_blank'
            tabIndex={1}
            outlined={!withoutShareButton}
            className='w-full'
            onEnter={null}
            onClick={() => gaService.sendEvent('Saboteur Assessment', 'click', 'learn_more_link')}
          />
        )}
      </div>
    </div>
  </div>
);

export default ContentContainer;
