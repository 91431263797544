import React from 'react';
import Button from '../Page/Button';
import handOnStove from '../../img/handOnStove2.png';
import AssessmentInfoPage from '../Page/AssessmentInfoPage';

const AssessmentResultMeaningPage = ({
  history,
  match: {
    params: {resultId}
  }
}) => {
  const handleMoveToPqResultsVortexPage = () => {
    history.push(`/pq/results/${resultId}/vortex`);
  };

  return (
    <AssessmentInfoPage
      image={<div className='img-pq-meaning' />}
      title='Aren’t negative emotions useful?'
      wide
      text={
        <>
          <img alt='' className='h-138 lg:h-200 mb-40' src={handOnStove} />
          <p>
            If you touch a hot stove, it’s helpful to feel pain to alert yourself. Similarly,{' '}
            <strong>negative feelings are helpful to alert you</strong> to important things. If you
            stay in negative emotions, you’re keeping your hands on the hot stove and sabotaging
            your ability to respond optimally.
          </p>
          <p>
            A key to mental fitness is{' '}
            <strong>how quickly you can recover from negative to positive response.</strong>
          </p>
        </>
      }
      button={
        <Button
          label='Your score on PQ Vortex'
          tabIndex={0}
          className='w-full'
          onClick={handleMoveToPqResultsVortexPage}
          onEnter={handleMoveToPqResultsVortexPage}
        />
      }
    />
  );
};

export default AssessmentResultMeaningPage;
