import React from 'react';
import get from 'lodash/get';
import compact from 'lodash/compact';
import toLower from 'lodash/toLower';
import {FieldArray} from 'formik';
import Input from '../Form/Input';
import Button from '../Page/Button';
import crossIcon from '../../img/cross.svg';
import redCrossIcon from '../../img/cross_red.svg';
import checkIcon from '../../img/chk1.png';
import FormikField from '../Form/FormikField';
import emailRegex from '../../const/emailRegex';

const ApplicationPodForm = ({values, errors, touched, handleChange, handleBlur}) => {
  return (
    <>
      <FormikField name='title' {...{errors, touched}}>
        <Input
          type='text'
          label='Pod Name*'
          name='title'
          placeholder='The Happy Podders'
          className='w-full'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.title}
        />
      </FormikField>
      <FormikField name='email' {...{errors, touched}}>
        <Input
          type='email'
          label='Pod Leader Email'
          name='email'
          className='w-full'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          disabled
        />
      </FormikField>
      <div className='body mt-30'>
        Fill in the email addresses of the 2 to 5 people you’d like to invite to your Pod. All
        members of a Pod must meet the program eligibility requirements. Each member should have
        already applied and been accepted.
      </div>
      <FieldArray
        name='emails'
        render={({push, remove}) => (
          <div>
            {values.emails.map((email, index) => (
              <div key={index}>
                <FormikField name={`emails.${index}`} {...{errors, touched}}>
                  <div className='flex flex-row'>
                    <Input
                      type='text'
                      label={`Email Address #${index + 1}`}
                      name={`emails.${index}`}
                      placeholder='name@example.com'
                      className='w-full flex-1'
                      onChange={({target}) =>
                        handleChange({
                          target: {
                            name: target.name,
                            value: (target.value || '').trim()
                          }
                        })
                      }
                      onBlur={handleBlur}
                      value={email}
                    />
                    <div className='flex-initial'>
                      {index > 1 ? (
                        <div
                          className='cursor-pointer w-30 h-30 mt-18 ml-10 lg:mt-0'
                          onClick={() => remove(index)}
                        >
                          <img src={crossIcon} alt='' />
                        </div>
                      ) : null}
                    </div>
                    <div className='flex-initial'>
                      {!get(errors, `emails.${index}`) && email ? (
                        <div className='w-30 h-30 mt-18 ml-10 lg:mt-0'>
                          <img className='w-full h-full' src={checkIcon} alt='' />
                        </div>
                      ) : null}
                      {get(errors, `emails.${index}`) &&
                      get(touched, `emails.${index}`) &&
                      email ? (
                        <div className='w-30 h-30 mt-18 ml-10 lg:mt-0'>
                          <img className='w-full h-full' src={redCrossIcon} alt='' />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </FormikField>
              </div>
            ))}
            {values.emails.length < 5 ? (
              <Button label='Add More Emails' className='mt-45 mb-25' onClick={() => push('')} />
            ) : null}
          </div>
        )}
      />
    </>
  );
};

export const validateForm = async (values, checkEmail) => {
  const errors = {};
  const emailErrors = [];

  if (!values.title) {
    errors.title = 'This field is required';
  }

  for (let i = 0; i < values.emails.length; i++) {
    const email = values.emails[i];

    if (!email) {
      emailErrors[i] = 'This field is required';
    } else if (email && !emailRegex.test(email)) {
      emailErrors[i] = 'The email address is not valid';
    } else if (values.emails.map(toLower).filter((e) => e === toLower(email)).length > 1) {
      emailErrors[i] = 'All Pod email addresses must be unique';
    } else {
      emailErrors[i] = await checkEmail(email);
    }
  }

  if (compact(emailErrors).length) {
    errors.emails = emailErrors;
  }

  if (Object.keys(errors).length > 0) {
    errors.form = 'Please fix validation errors';
  }

  return errors;
};

export default ApplicationPodForm;
