import {observable, computed, action, transaction, autorun} from 'mobx';
import _ from 'lodash';
import axios from 'axios';
import store from 'store';
import moment from 'moment-timezone';
import questionnaire from '../const/pq_questionnaire.json';
import defaultAboutYouQuestions from '../const/about_you_questions';
import {LOW_V3_CAPTCHA_SCORE_CODE} from '../const/captcha_error_code';

const aboutYouQuestions = [
  {
    label:
      'How typical has the last 24 hours been compared to the challenges you face on an average weekday?',
    type: 'select',
    allowCustom: false,
    field: 'how_typical',
    items: [
      {value: 'Less challenging than average day', id: 'less_than_average'},
      {value: 'Average day', id: 'average'},
      {value: 'More challenging than average day', id: 'more_than_average'}
    ]
  },
  ...defaultAboutYouQuestions
];

const CAPTCHA_ACTION_NAME = 'pq_assessment_submit';
const STORE_KEY = 'pq_assessment_store';
const baseResultsUrl = `${process.env.REACT_APP_ASSESSMENT_API_URL}/assessment/results`;
const baseUrl = `${baseResultsUrl}/pq`;
const defaultAssessmentData = {
  how_typical: '',
  job_status: '',
  why: '',
  gender: '',
  age: '',
  first_name: '',
  last_name: '',
  email: '',
  personal_email_blocked: false,
  use_personal_email: false,
  company_id: null,
  utm_source: null,
  utm_medium: null,
  utm_campaign: null,
  utm_content: null,
  utm_term: null,
  lead_source: null,
  to_sync: false,
  send_confirmation_email: false,
  invalid_fields: {
    first_name: false,
    email: false
  },
  touched_fields: {
    email: false
  },
  form_error: ''
};

export default class PQAssessmentStore {
  questionnaire = questionnaire;

  @observable preliminaryScoreAlerted = false;
  @observable answers = [];
  @observable assessmentData = _.clone(defaultAssessmentData);

  @observable result = {
    id: null,
    pending: false,
    data: {
      first_name: '',
      last_name: '',
      confirmed: false,
      pq: null,
      pq_percentile: null
    }
  };

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.fromJson(store.get(STORE_KEY) || {});

    autorun(() => {
      store.set(STORE_KEY, this.asJson);
    });
  }

  @computed get asJson() {
    return {
      answers: this.answers,
      assessmentData: this.assessmentData
    };
  }

  fromJson(json) {
    this.answers = json.answers || [];
    this.assessmentData = json.assessmentData
      ? {
          ...defaultAssessmentData,
          ...json.assessmentData,
          form_error: ''
        }
      : this.assessmentData;
  }

  get questions() {
    return this.questionnaire.questions;
  }

  get aboutYouQuestions() {
    return aboutYouQuestions;
  }

  @computed get answersMap() {
    return _(this.answers).keyBy('question_id').mapValues('value').value();
  }

  @action addAnswer(questionId, answer) {
    const existingAnswer = this.answers.find((answer) => answer.question_id === questionId);

    if (existingAnswer) {
      existingAnswer.value = answer;
      return;
    }

    this.answers.push({
      question_id: questionId,
      value: answer
    });
  }

  @computed get firstUnansweredQuestionNo() {
    const {questions, answersMap} = this;
    const no = questions.findIndex((q) => answersMap[q.id] === undefined);

    return no === -1 ? null : no + 1;
  }

  @action toBeUpdated() {
    Object.assign(this.assessmentData, this.result.data);
  }

  postAssessmentResultsData(tokenV3) {
    let action;
    const {result, assessmentData} = this;
    result.pending = true;

    const data = _.pick(assessmentData, ['first_name', 'last_name', 'email']);

    if (this.rootStore.captchaStore.tokenV2) {
      data.recaptcha_token_v2 = this.rootStore.captchaStore.tokenV2;
    } else if (tokenV3) {
      data.recaptcha_token_v3 = tokenV3;
    }

    data.timezone = moment.tz.guess() || '';
    data.useragent = navigator.userAgent;

    if (result.id) {
      action = axios.post(`${baseUrl}/${result.id}/data`, data);
    } else {
      Object.assign(
        data,
        _.pick(assessmentData, [
          'to_sync',
          'send_confirmation_email',
          'job_status',
          'why',
          'company_id',
          'utm_source',
          'utm_medium',
          'utm_campaign',
          'utm_content',
          'utm_term',
          'lead_source',
          'gender',
          'age',
          'how_typical',
          'from_app',
          'is_coaching',
          'hear_about_pq'
        ])
      );
      data.answers = this.answers;
      action = axios.post(baseUrl, data);
    }

    return action;
  }

  @action async saveAssessmentResultsData() {
    const tokenV3 = await this.rootStore.captchaStore.verifyCaptchaV3(CAPTCHA_ACTION_NAME);
    const action = this.postAssessmentResultsData(tokenV3);

    return action
      .then(({data}) =>
        transaction(() => {
          this.result.id = data.update_id;
          Object.assign(this.result.data, data);
          this.answers = [];
          this.assessmentData = _.clone(defaultAssessmentData);
        })
      )
      .catch((err) => {
        if (
          err?.response?.status === 403 &&
          err?.response?.data?.code === LOW_V3_CAPTCHA_SCORE_CODE
        ) {
          this.rootStore.captchaStore.verifyCaptchaV2();
          throw err;
        } else {
          throw err;
        }
      })
      .finally(() => {
        this.result.pending = false;
      });
  }

  @action confirmAssessmentResult = (id, code) => {
    return axios.post(`${baseResultsUrl}/${id}/confirmation`, {code});
  };

  @action loadAssessmentResults = (id) => {
    if (this.result.id === id) {
      return Promise.resolve();
    }

    this.result.pending = true;
    this.result.id = id;

    return axios.get(`${baseUrl}/${id}`).then(({data}) =>
      transaction(() => {
        Object.assign(this.result.data, data);
        this.result.pending = false;
      })
    );
  };
}
