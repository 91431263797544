import React from 'react';
import healthyConflict from '../../img/shareContentImages/healthyConflict.png';
import earnedTrust from '../../img/shareContentImages/earnedTrust.png';
import ContentContainer from './ContentContainer';

const B2BIndividualContent = ({clientName, clientType}) => (
  <ContentContainer
    className='bg-yellow min-w-390'
    title='Free your team from stress'
    description='Share PQ Program with your leader to improve wellbeing and performance of your team'
    clientName={clientName}
    clientType={clientType}
    refId='a-bin'
  >
    <div className='flex pt-24 sm:pt-40 w-full justify-center'>
      <div className='flex w-161 sm:w-141 h-154 flex-col bg-yellow-light rounded-lg justify-between p-16 items-center'>
        <div className='w-70 h-70 self-center'>
          <img className='w-full h-full' src={healthyConflict} alt='' />
        </div>
        <div className='heading-7 text-center max-w-min'>Healthy Conflict</div>
      </div>
      <div className='flex w-161 sm:w-141 h-154 flex-col rounded-lg justify-between p-16 ml-8 border-2 items-center'>
        <div className='w-70 h-70 self-center'>
          <img className='w-full h-full' src={earnedTrust} alt='' />
        </div>
        <div className='heading-7 text-center max-w-min'>Earned Trust</div>
      </div>
    </div>
  </ContentContainer>
);

export default B2BIndividualContent;
