import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import LoadingPage from '../LoadingPage';
import Button from '../Page/Button';
import PQScore from './PQScore';
import AssessmentTextPage from '../Page/AssessmentTextPage';

class AssessmentResultMeaningPage extends Component {
  UNSAFE_componentWillMount() {
    const {
      pqStore,
      match: {
        params: {resultId}
      }
    } = this.props;

    pqStore.loadAssessmentResults(resultId);
  }

  getButtonText(pq) {
    if (pq > 90) {
      return 'Why Improve My PQ';
    }

    return 'Why Boost My PQ';
  }

  renderDescription(pq) {
    if (pq >= 90) {
      return (
        <>
          <p>
            Your mental fitness level is <strong>In the Zone</strong>. In this state, you’re capable
            of effortless mastery. Rather than working “hard” to generate results, you harness the
            power of full presence and ease and flow.
          </p>
          <p>
            <strong>Performance</strong>: You experience <strong>peak performance</strong>, the
            maximal output that your skills and knowledge make possible. You’re at maximal
            resilience, able to quickly recover from setbacks and actively find how to convert any
            setbacks into gifts and opportunities. You bring a calm, clear-headed laser-focus to
            tasks and challenges, able to experience both the joy and power of complete presence
            with the task at hand. Due to contagion effect, you are able to bring out the best in
            others.
          </p>
          <p>
            <strong>Happiness/Stress</strong>: Your baseline{' '}
            <strong>happiness is steady and extremely high</strong> as you primarily experience
            positive emotions such as empathy, gratitude, curiosity, joy of creativity, peace, and
            calm even in the midst of great challenges. Any negative emotions are short-lived
          </p>
          <p>
            <strong>Relationships</strong>: Your important relationships are in{' '}
            <strong>great harmony.</strong> You are able to use high empathy and curiosity to
            convert any conflicts which arise into gifts of deepening understanding and trust.
          </p>
        </>
      );
    }

    if (pq >= 80) {
      return (
        <>
          <p>
            Your mental fitness level is <strong>Thriving.</strong>
            <br />
            This represents high mental fitness. Since your positivity to negativity strength ratio
            is well above 3/1, you often feel the{' '}
            <strong>uplifting pull of the net-positive vortex</strong> inside your own mind. As a
            result, you often exude an energy and aura of positivity, which is also uplifting to
            others, due to the mirror neurons’ contagion effect.
          </p>
          <p>
            <strong>Performance</strong>: You’re <strong>close to peak performance,</strong> the
            maximal output that your current skills and knowledge make possible. You demonstrate
            high resilience where you quickly recover from setbacks and actively find how to convert
            any setbacks into gifts and opportunities. You know how to access the great positive
            powers of your mind, which might include empathy, curiosity, creativity, and calm,
            clear-headed laser-focused action.
          </p>
          <p>
            <strong>Happiness/Stress</strong>: Your baseline happiness is relatively steady and
            high, as you primarily experience positive emotions. Any negative emotions are
            short-lived.
          </p>
          <p>
            <strong>Relationships</strong>: Your important relationships are mostly in{' '}
            <strong>great harmony.</strong> You are able to use high empathy and curiosity to
            convert the inevitable conflicts which arise into gifts of deepening understanding and
            trust.
          </p>
        </>
      );
    }

    if (pq >= 71) {
      return (
        <>
          <p>
            Your mental fitness level is <strong>On the Cusp.</strong> Your score is in the tipping
            point zone.
          </p>
          <p>
            In handling challenges and relationships, you are very close to the recommended 3/1
            ratio of positive mode (curiosity, empathy, joyful creativity, calm clear-headed action,
            etc.) to negative (stress, anxiety, frustration, regret, guilt, etc.). Since you’d need
            at least 3 positives to counteract 1 negative, you likely{' '}
            <strong>
              sway back and forth often between the net-positive and net-negative vortex in your
              brain.
            </strong>
          </p>
          <p>
            When you drift to the net-negative vortex, you waste some mental and emotional energy to
            counter this circular negative drag in your mind. The negative impact on performance,
            happiness, and relationships is noticeable for <strong>three reasons:</strong>
          </p>
          <ol>
            <li>
              When you are in the negative mode, you do more{' '}
              <strong>damage to your relationships, performance, and happiness</strong> than you’re
              able to counteract when you recover to the positive.
            </li>
            <li>
              Your <strong>recovery time </strong>from the negative to positive takes longer than
              ideal.
            </li>
            <li>
              You <strong>waste mental and emotional energy</strong> from yourself and perhaps
              others in the process.
            </li>
          </ol>
          <p>
            When you shift to the net-positive vortex, you feel uplifted by the internal positive
            pull of your mind and find yourself handling challenges and relationships with much
            greater ease and effectiveness. This{' '}
            <strong>back and forth between the positive and negative modes</strong> can be confusing
            or disorienting to both you and others.
          </p>
        </>
      );
    }

    if (pq >= 50) {
      return (
        <>
          <p>
            Your mental fitness level is <strong>Burdened.</strong>
          </p>
          <p>
            In handling challenges and relationships, you use a little more positive mental mode
            (curiosity, empathy, joyful creativity, calm clear-headed action, etc.) than negative
            (stress, anxiety, frustration, regret, guilt, etc.).
          </p>
          <p>
            However, since you’d need at least 3 positives to counteract 1 negative, you’re still
            being <strong>dragged down by the net-negative vortex</strong> inside your brain. You
            waste a good deal of mental and emotional energy to counter this self-reinforcing and
            circular negative drag in your mind, which is orchestrated by Cortisol, the stress
            hormone.
          </p>
          <p>
            The negative impact on performance, happiness, and relationships is significant, for
            three reasons:
          </p>
          <ol>
            <li>
              When you are in the negative mode, you do more{' '}
              <strong>damage to your relationships, performance, and happiness</strong> than you’re
              able to counteract when you recover to the positive.
            </li>
            <li>
              Your <strong>recovery time</strong> from the negative to positive is still too long.
            </li>
            <li>
              You’ve <strong>wasted a ton of mental and emotional energy</strong> from yourself and
              perhaps others in the process.
            </li>
          </ol>
          <p>
            The great news is that you’re so <strong>close to the tipping point,</strong> above
            which you’d flip to feeling continually uplifted by the net-positive vortex of your
            brain, orchestrated by Endorphins. The result would be disproportionately large
            improvements in your performance, happiness, and relationships.
          </p>
        </>
      );
    }

    if (pq >= 25) {
      return (
        <>
          <p>
            Your mental fitness level is <strong>Surviving.</strong>
          </p>
          <p>
            In handling challenges and relationships, you use more negative mental modes (stress,
            anxiety, frustration, regret, guilt, etc.) than positive (curiosity, empathy, joyful
            creativity, calm clear-headed action, etc.).
          </p>
          <p>
            Since you’d <strong>need at least 3 positives to counteract 1 negative,</strong> you’re
            in a great positive/negative deficit, being{' '}
            <strong>dragged down by the net-negative vortex</strong> inside your brain (orchestrated
            by Cortisol, the stress hormone). You{' '}
            <strong>waste a great deal of mental and emotional energy</strong> to counter this
            self-reinforcing and circular negative drag in your mind.
          </p>
          <p>
            The negative impact on performance, happiness, and relationships is very significant,
            for three reasons:
          </p>
          <ol>
            <li>
              When you are in the negative mode, you do more{' '}
              <strong>damage to your relationships, performance, and happiness</strong> than you’re
              able to counteract when you recover to the positive.
            </li>
            <li>
              Your <strong>recovery time</strong> from the negative to positive is much long than it
              could be.
            </li>
            <li>
              You <strong>waste a lot of mental and emotional energy</strong> from yourself and
              perhaps others in the process.
            </li>
          </ol>
          <p>
            This constant swimming against the internal currents of your own mind is{' '}
            <strong>exhausting</strong> and amounts to “surviving” rather than thriving.
          </p>
        </>
      );
    }

    return (
      <>
        <p>
          Your mental fitness level is <strong>At Risk.</strong>
        </p>
        <p>
          In handling challenges and relationships, you use a lot more negative mental modes
          (stress, anxiety, frustration, regret, guilt, etc.) than positive (curiosity, empathy,
          joyful creativity, calm clear-headed action, etc.).
        </p>
        <p>
          Since you’d <strong>need at least 3 positives to counteract 1 negative,</strong> you’re in
          a risky positive/negative deficit, powerfully{' '}
          <strong>dragged down by the net-negative vortex</strong> inside your mind (orchestrated by
          the stress hormone Cortisol.) You waste most of your mental and emotional energy to handle
          this self-reinforcing and circular negative drag in your mind.
        </p>
        <p>
          The negative impact on performance, happiness, and relationships is extremely significant,
          for three reasons:
        </p>
        <ol>
          <li>
            When you are in the negative mode, you do more{' '}
            <strong>damage to your relationships, performance, and happiness</strong> than you’re
            able to counteract when you recover to the positive.
          </li>
          <li>
            Your <strong>recovery time</strong> from the negative to positive is far longer than it
            should be.
          </li>
          <li>
            You <strong>waste a lot of mental and emotional energy</strong> from yourself and
            perhaps others in the process.
          </li>
        </ol>
        <p>
          This constant swimming against the internal currents of your own mind probably feels
          exhausting and demoralizing. <strong>You are at risk of deepening harm,</strong> both
          mentally and physically, if you don’t take action to reverse these dynamics.
        </p>
        <p>
          You may be facing some considerable life challenges right now. Taking care of your mental
          wellness may need additional support from a mental health professional.
        </p>
      </>
    );
  }

  render() {
    const {
      pqStore,
      history,
      match: {
        params: {resultId}
      }
    } = this.props;
    const {pending, data} = pqStore.result;
    const pq = Math.round(data.pq);
    const handleMoveToPqResultsImpactPage = () => {
      history.push(`/pq/results/${resultId}/impact`);
    };

    if (pending) {
      return <LoadingPage />;
    }

    return (
      <AssessmentTextPage>
        <PQScore score={pq} />
        <h3 className='heading-4 lg:heading-3 pt-40'>What Your Score Reveals</h3>
        <div className='body pt-21 lg:pt-40'>{this.renderDescription(pq)}</div>
        <Button
          label={this.getButtonText(pq)}
          tabIndex={0}
          className='mt-30'
          onClick={handleMoveToPqResultsImpactPage}
          onEnter={handleMoveToPqResultsImpactPage}
        />
      </AssessmentTextPage>
    );
  }
}

export default inject('pqStore')(observer(AssessmentResultMeaningPage));
