import jobStatuses from './jobStatuses';

const aboutYouQuestions = [
  {
    label: 'How old are you?',
    type: 'select',
    allowCustom: false,
    field: 'age',
    items: [
      {value: '18 - 25'},
      {value: '26 - 35'},
      {value: '36 - 45'},
      {value: '46 - 55'},
      {value: '56 - 65'},
      {value: '65+'},
      {value: 'I prefer not to say'}
    ],
    shouldBeSkipped: (data) => data.from_app
  },
  {
    label: 'What is your gender?',
    type: 'select',
    allowCustom: true,
    field: 'gender',
    items: [{value: 'Man'}, {value: 'Woman'}, {value: 'I prefer not to say'}],
    shouldBeSkipped: (data) => data.from_app
  },
  {
    label: 'Were you referred by a coach?',
    type: 'select',
    allowCustom: false,
    field: 'is_referred_by_coach',
    items: [
      {id: true, value: 'Yes'},
      {id: false, value: 'No'}
    ],
    shouldBeSkipped: (data) => data.from_app
  },
  {
    label: 'What is your current work status?',
    type: 'select',
    allowCustom: false,
    field: 'job_status',
    items: [
      {id: jobStatuses.business_leader, value: 'Business Leader'},
      {id: jobStatuses.employed, value: 'Employed'},
      {id: jobStatuses.self_employed, value: 'Self-employed or freelance'},
      {id: jobStatuses.part_time, value: 'Part Time'},
      {id: jobStatuses.not_working, value: 'Not working / in-between jobs'}
    ],
    shouldBeSkipped: (data) => data.from_app
  },
  {
    label: 'Is coaching your primary source of income?',
    type: 'select',
    allowCustom: false,
    field: 'is_coaching',
    items: [
      {id: true, value: 'Yes'},
      {id: false, value: 'No'}
    ],
    shouldBeSkipped: (data) =>
      [jobStatuses.employed, jobStatuses.not_working].includes(data.job_status) || data.from_app
  }
];

export default aboutYouQuestions;
