import React from 'react';
import {inject, observer} from 'mobx-react';
import ShareViaEmailButton from '../ShareViaEmailButton';
import ShareViaCopyButton from '../ShareViaCopyButton';

const ShareResults = ({sabStore}) => {
  if (!sabStore?.result?.id) {
    return null;
  }

  return (
    <div className='pt-40'>
      Share results
      <div className='pt-8'>
        <ShareViaCopyButton
          text='Copy link'
          tabIndex={0}
          textToCopy={`${process.env.REACT_APP_APP_URL}/saboteur/results/${sabStore.result.id}`}
        />
        <div className='inline-block ml-16'>
          <ShareViaEmailButton
            text='Send email'
            subject={`Saboteur Assessment Results from ${sabStore.result.data.first_name}`}
            body={`On behalf of Positive Intelligence, ${sabStore.result.data.first_name} asked us to share their Saboteur Assessment Results with you. Here you go: ${process.env.REACT_APP_APP_URL}/saboteur/results/${sabStore.result.id}`}
          />
        </div>
      </div>
    </div>
  );
};

export default inject('sabStore')(observer(ShareResults));
