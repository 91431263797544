import React, {useState, useCallback, useMemo, useRef} from 'react';
import flattenDeep from 'lodash/flattenDeep';
import ReSelect from 'react-select';
import Input from './Input';

const customValue = '__custom__';
const customOption = {value: customValue, label: 'Other (Specify)'};

const customStyles = {
  container: (provided) => ({
    ...provided,
    height: '100%'
  }),
  control: (provided) => ({
    ...provided,
    border: 'none',
    borderBottom: '1px solid #DEDEDE',
    boxShadow: 'none',
    '&:hover': {
      border: 'none',
      borderBottom: '1px solid #DEDEDE'
    },
    borderRadius: 0,
    fontSize: 'inherit',
    cursor: 'pointer',
    margin: '0 0 0 -8px',
    outline: 'none'
  }),
  singleValue: (provided) => ({
    ...provided,
    margin: 0,
    color: 'black',
    padding: '5px 0'
  }),
  placeholder: (provided) => ({
    ...provided,
    margin: 0,
    padding: '5px 0',
    color: 'black-57'
  }),
  groupHeading: (provided) => ({
    ...provided,
    padding: '6px 14px',
    fontSize: 14
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '10px 14px',
    fontSize: 'inherit',
    fontWeight: '500',
    cursor: 'pointer',
    backgroundColor: state.isSelected
      ? 'rgba(0, 0, 0, 0.3)'
      : state.isFocused
      ? 'rgba(0, 0, 0, 0.05)'
      : 'inherit'
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: 'inherit',
    borderRadius: 0,
    border: '1px solid #DEDEDE',
    boxShadow: 'none'
  })
};

const Select = ({
  items,
  allowCustom,
  value,
  onSelect,
  onChange,
  isSearchable = false,
  tabIndex
}) => {
  const textInput = useRef();

  const [isFocused, setIsFocused] = useState(false);

  const [customMode, setCustomMode] = useState(() => {
    if (!allowCustom || value === '') {
      return false;
    }

    const allItems = flattenDeep(items.map((item) => item.items || item));

    // enable custom mode if the value is not found in items
    return !allItems.find((item) => (item.id ?? item.value) === value);
  });

  const handleSelect = useCallback(
    ({value}) => {
      if (value === customValue) {
        setCustomMode(true);
        onChange('');
        setTimeout(() => textInput.current.focus());
      } else {
        setCustomMode(false);
        onSelect(value);
      }
    },
    [onChange, onSelect]
  );

  const handleInputChange = useCallback(({target: {value}}) => onChange(value), [onChange]);

  const options = useMemo(() => {
    const options = items.map((item) => {
      if (item.header) {
        return {
          label: item.header,
          options: item.items.map((subItem) => {
            return {value: subItem.id ?? subItem.value, label: subItem.value};
          })
        };
      }

      return {value: item.id ?? item.value, label: item.value};
    });

    if (allowCustom) {
      options.push(customOption);
    }

    return options;
  }, [allowCustom, items]);

  const allOptions = flattenDeep(options.map((option) => option.options || option));
  const selectedOption = customMode
    ? customOption
    : allOptions.find((option) => option.value === value);

  const handleFocus = () => setIsFocused(true);

  const handleBlur = () => setIsFocused(false);

  const handleMouseDown = () => setIsFocused(false);

  const handleTouchEnd = () => setIsFocused(false);

  return (
    <div>
      <div
        className={`${isFocused ? 'outline-blue rounded-6' : ''} pl-8 small-type`}
        onMouseDown={handleMouseDown}
        onTouchEnd={handleTouchEnd}
      >
        <ReSelect
          onFocus={handleFocus}
          onBlur={handleBlur}
          tabSelectsValue={false}
          options={options}
          value={selectedOption}
          onChange={handleSelect}
          menuPortalTarget={document.body}
          isSearchable={isSearchable}
          placeholder='Select an option'
          styles={customStyles}
          tabIndex={tabIndex}
        />
      </div>
      {customMode ? (
        <Input
          ref={textInput}
          className='mt-20 w-full pl-8'
          tabIndex={tabIndex}
          value={value}
          placeholder='Type here...'
          onChange={handleInputChange}
        />
      ) : null}
    </div>
  );
};

export default Select;
