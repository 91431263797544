import React, {useEffect} from 'react';
import store from 'store';

export const OPTIN_STORE_KEY = 'pq_optin_form';

const OptInForm = ({email, onOptIn, backUrl}) => {
  useEffect(() => {
    const handleNewMessage = (e) => {
      if (e.data === 'opted_in') {
        onOptIn();
      }
    };

    window.addEventListener('message', handleNewMessage);

    store.set(OPTIN_STORE_KEY, {backUrl});

    return () => window.removeEventListener('message', handleNewMessage);
  }, [onOptIn, backUrl]);

  return (
    <div className='relative z-3' aria-labelledby='modal-title' role='dialog' aria-modal='true'>
      <div className='fixed inset-0 bg-black bg-opacity-75 transition-opacity' />

      <div className='fixed inset-0 z-3 overflow-y-auto'>
        <div className='flex min-h-full items-center justify-center p-4'>
          <div className='relative transform overflow-hidden rounded-lg bg-white w-full max-w-600 h-650 max-h-full'>
            <iframe
              title='Opt In'
              src={`/opt_in?email=${encodeURIComponent(email)}`}
              className='h-full w-full'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OptInForm;
