import React, {Component} from 'react';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import Analytics from './Analytics';

export default class Root extends Component {
  render() {
    return (
      <BrowserRouter>
        <Analytics>
          <App />
        </Analytics>
      </BrowserRouter>
    );
  }
}
