import Button from '../../Page/Button';
import ShareButtonMenu from './ShareButtonMenu';
import React, {useRef, useState} from 'react';
import useClickOutside from './useClickOutside';

const ShareButton = ({clientName, clientType, refId}) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const wrapperRef = useRef('menu');
  useClickOutside(wrapperRef, () => {
    setMenuOpen(false);
  });
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <div className='relative' ref={wrapperRef}>
      <Button
        label='Share'
        className='w-full mb-16 h-44 flex justify-center items-center'
        tabIndex={0}
        onEnter={null}
        onClick={toggleMenu}
      />
      {isMenuOpen && <ShareButtonMenu {...{clientName, clientType, refId}} />}
    </div>
  );
};

export default ShareButton;
