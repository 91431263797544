import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import LoadingPage from '../LoadingPage';
import SavedResult from '../SavedResult';

class SavedResultsPage extends Component {
  UNSAFE_componentWillMount() {
    const {
      pqStore,
      match: {
        params: {resultId}
      }
    } = this.props;

    pqStore.loadAssessmentResults(resultId);
  }

  render() {
    const {
      history,
      pqStore,
      match: {
        params: {resultId}
      }
    } = this.props;
    const {pending} = pqStore.result;

    if (pending) {
      return <LoadingPage />;
    }

    return (
      <SavedResult
        history={history}
        email={pqStore.result.data.email}
        clientType={pqStore.result.data.client_type}
        updateLink={`/pq/update/${resultId}`}
        tempLink={`/pq/results/${pqStore.result.data.temp_link_id}`}
      />
    );
  }
}

export default inject('pqStore')(observer(SavedResultsPage));
