import React, {useLayoutEffect, useCallback, useState} from 'react';
import {inject, observer} from 'mobx-react';
import {Formik} from 'formik';
import toLower from 'lodash/toLower';
import queryString from 'query-string';
import {useLocation} from 'react-router-dom';
import Button from '../Page/Button';
import Loading from '../Loading';
import LoadingPage from '../LoadingPage';
import FormError from '../Form/FormError';
import ApplicationPodForm, {validateForm} from './PodForm';
import AssessmentCenteredPage from '../Page/AssessmentCenteredPage';
import AssessmentWideCenteredPage from '../Page/AssessmentWideCenteredPage';

const renderMessage = (title, message, contactInfo) => (
  <AssessmentCenteredPage>
    <h2 className='heading-3'>{title}</h2>
    <div className='body mt-40'>
      {message}
      {contactInfo ? (
        <>
          <br />
          <br />
          Please contact Support at{' '}
          <a href='mailto:support@positiveintelligence.com'>support@positiveintelligence.com</a> if
          you need assistance.
        </>
      ) : null}
    </div>
  </AssessmentCenteredPage>
);

const checkingEmails = {};

const checkEmail = (podsStore) => async (_email) => {
  if (!_email) {
    return;
  }

  const email = toLower(_email);

  if (email === toLower(podsStore.leader)) {
    return 'Your email address is already part of the Pod';
  }

  if (!checkingEmails[email]) {
    checkingEmails[email] = podsStore.checkEmail(email);
  }

  const {available} = await checkingEmails[email];

  if (!available) {
    return 'The email address is already in another pod. Please contact Support if you need assistance.';
  }
};

const checkingTitles = {};

const checkTitle = (podsStore) => async (_title) => {
  if (!_title) {
    return;
  }

  const title = toLower(_title);

  if (!checkingTitles[title]) {
    checkingTitles[title] = podsStore.checkTitle(title);
  }

  const {available} = await checkingTitles[title];

  if (!available) {
    return 'The pod title is already in use';
  }
};

const PodPage = ({
  podStore,
  match: {
    params: {secret}
  }
}) => {
  const location = useLocation();
  const [submitted, setSubmitted] = useState(false);
  const leader = queryString.parse(location.search).email;
  useLayoutEffect(() => {
    podStore.secret = secret;
    podStore.leader = leader;
    podStore.loadToEdit();
  }, [leader, podStore, secret]);

  const {pending, data} = podStore.podToEdit;
  const handleSubmitForm = async (values, actions) => {
    try {
      const teamList = [
        {
          is_leader: true,
          email: values.email,
          first_name: values.first_name,
          last_name: values.last_name
        },
        ...values.team_list
      ];

      if (data.pod?._id) {
        await podStore.update(data.pod._id, {
          title: values.title,
          team_list: teamList
        });
      } else {
        await podStore.submit({
          title: values.title,
          team_list: teamList
        });
      }

      setSubmitted(true);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        actions.setErrors({form: err.response.data.message});
      }
    }
  };

  const validate = useCallback(
    async (values) => validateForm(values, checkEmail(podStore), checkTitle(podStore)),
    [podStore]
  );

  if (pending || !data.status) {
    return <LoadingPage />;
  }

  if (data.status === 'in_other_pod') {
    return renderMessage(
      'Not Available',
      data.pod?.title
        ? `You are already a member of the Pod "${data.pod.title}"`
        : 'You are already a member of a Pod',
      true
    );
  }

  const isNew = data.status === 'not_found';

  if (submitted) {
    return renderMessage(
      'Success',
      isNew ? (
        <div>
          Your Pod is successfully created.
          <br />
          Check your email inbox for your confirmation email.
        </div>
      ) : (
        'Your Pod is successfully updated'
      )
    );
  }

  const initialValues = isNew
    ? {
        title: '',
        email: leader,
        first_name: '',
        last_name: '',
        team_list: [{email: '', first_name: '', last_name: ''}]
      }
    : {};

  if (!isNew) {
    const leaderMember = data.pod.team_list.find((m) => m.is_leader);
    initialValues.title = data.pod.title;
    initialValues.email = leaderMember.email;
    initialValues.first_name = leaderMember.first_name;
    initialValues.last_name = leaderMember.last_name;

    initialValues.team_list = data.pod.team_list
      .filter((m) => m.email !== leaderMember.email)
      .map((m) => ({
        email: m.email,
        first_name: m.first_name,
        last_name: m.last_name
      }));
  }

  return (
    <AssessmentWideCenteredPage>
      <h2 className='heading-4 lg:heading-3'>{isNew ? 'Create your Pod' : 'Update your Pod'}</h2>
      <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmitForm}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          submitForm,
          isSubmitting,
          setFieldValue,
          submitCount
        }) => (
          <div className='body'>
            <form onSubmit={handleSubmit}>
              {isSubmitting ? <Loading /> : null}

              <ApplicationPodForm
                limit={data.limit || 5}
                {...{
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  setFieldValue
                }}
              />

              {errors.form && !!submitCount ? (
                <FormError className='mt-30'>{errors.form}</FormError>
              ) : null}
              <Button
                className='mt-20'
                label={isNew ? 'Complete Pod' : 'Update Pod'}
                onClick={submitForm}
                disabled={isSubmitting}
              />
            </form>
          </div>
        )}
      </Formik>
    </AssessmentWideCenteredPage>
  );
};

export default inject('podStore')(observer(PodPage));
