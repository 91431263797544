import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import {Formik} from 'formik';
import Loading from '../Loading';
import LoadingPage from '../LoadingPage';
import Button from '../Page/Button';
import FormError from '../Form/FormError';
import ApplicationFormFields, {validateForm, applicationFields} from './ApplicationFormFields';
import AssessmentCenteredPage from '../Page/AssessmentCenteredPage';

const UpdateApplicationPage = ({
  applicationStore,
  match: {
    params: {updateSecret}
  }
}) => {
  const [redirecting, setRedirecting] = useState(false);
  useEffect(() => {
    applicationStore.loadApplicationToEdit(updateSecret);
  }, [applicationStore, updateSecret]);
  const {pending, data} = applicationStore.applicationToEdit;

  const handleSubmitForm = async (values, actions) => {
    try {
      await applicationStore.update(
        updateSecret,
        pickBy(pick(values, applicationFields), (v) => v !== '')
      );

      setRedirecting(true);
      window.location.href = 'https://positiveintelligence.lpages.co/100x-application-submitted/';
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        actions.setErrors({form: err.response.data.message});
      }
    }
  };

  if (pending) {
    return <LoadingPage />;
  }

  const initialValues = {
    is_coach_confirmation: true,
    requirements_read_confirmation: true,
    ...applicationFields.reduce((fields, field) => ({...fields, [field]: data[field] || ''}), {})
  };

  const hiddenFields =
    data.description === 'google_adwords' ? ['country', 'description', 'referrer', 'timezone'] : [];

  return (
    <AssessmentCenteredPage>
      <h2 className='heading-4 lg:heading-3'>Update PQ Coaching Program Application</h2>
      <Formik initialValues={initialValues} validate={validateForm} onSubmit={handleSubmitForm}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          submitForm,
          isSubmitting,
          setFieldValue,
          submitCount
        }) => (
          <div className='body'>
            <form onSubmit={handleSubmit}>
              {isSubmitting || redirecting ? <Loading /> : null}

              <ApplicationFormFields
                update
                {...{
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  hiddenFields
                }}
              />

              {errors.form && !!submitCount ? (
                <FormError className='mt-30'>{errors.form}</FormError>
              ) : null}
              <Button
                className='mt-20'
                label='Update Application'
                onClick={submitForm}
                disabled={isSubmitting}
              />
            </form>
          </div>
        )}
      </Formik>
    </AssessmentCenteredPage>
  );
};

export default inject('applicationStore')(observer(UpdateApplicationPage));
