import React, {useCallback} from 'react';

const ElementInput = ({children, className = '', onEnter}) => {
  const handleKeyUp = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        return onEnter && onEnter();
      }
    },
    [onEnter]
  );

  return (
    <div className={className} onKeyUp={handleKeyUp}>
      {children}
    </div>
  );
};

export default ElementInput;
