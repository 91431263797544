import React, {useCallback} from 'react';
import {usePreventFocusOnClick} from '../../utils/usePreventFocusOnClick';

const Button = ({
  label,
  disabled,
  outlined,
  small,
  white,
  className = '',
  onClick,
  onEnter,
  tabIndex
}) => {
  const handleClick = useCallback(
    (...args) => {
      if (!disabled) {
        onClick && onClick(...args);
      }
    },
    [disabled, onClick]
  );

  const handlePreventFocusOnClick = usePreventFocusOnClick();

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter' && !disabled) {
        onEnter();
      }
    },
    [disabled, onEnter]
  );

  // subtract border
  const size = small ? (white ? 'px-18 py-6' : 'px-20 py-8') : white ? 'px-45 py-9' : 'px-47 py-11';

  return (
    <div
      className={`${
        disabled
          ? `${white ? 'opacity-30' : 'bg-black'} text-disabled cursor-default`
          : `${
              white
                ? 'bg-white hover:bg-whiteHover-background'
                : outlined
                ? 'bg-transparent text-black border-2'
                : 'bg-black hover:bg-blackHover-background'
            } cursor-pointer`
      } ${size} ${
        white ? 'small-type-medium border-2' : 'small-type text-white'
      } cursor-pointer focus:outline-blue inline-block rounded-full text-center ${className}
       `}
      onClick={handleClick}
      onMouseDown={handlePreventFocusOnClick}
      onKeyDown={handleKeyDown}
      tabIndex={tabIndex}
    >
      {label}
    </div>
  );
};

export default Button;
