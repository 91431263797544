import React from 'react';
import AssessmentInfoPage from '../Page/AssessmentInfoPage';
import Button from '../Page/Button';
import gaService from '../../ga.service';

const OverviewPage = ({history}) => {
  const handleMoveToInstructions = () => {
    gaService.sendEvent('Saboteur Assessment', 'click', 'sab_intro');
    history.push(`/saboteur/instructions`);
  };

  return (
    <AssessmentInfoPage
      image={<div className='img-sab-overview' />}
      title='9 Ways We Self Sabotage'
      subtitle='Assessment overview'
      text={
        <>
          <p>Based on Shirzad Chamine’s New York Times bestseller and Stanford lectures.</p>
          <p>
            Research included 500,000 participants, world class athletes, and leaders from every
            Fortune 100 company.
          </p>
        </>
      }
      button={
        <Button
          label='Next'
          tabIndex={0}
          onClick={handleMoveToInstructions}
          onEnter={handleMoveToInstructions}
        />
      }
    />
  );
};

export default OverviewPage;
