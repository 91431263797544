import moment from 'moment-timezone';
import sortBy from 'lodash/sortBy';

const timezones = [
  {timezone: 'Pacific/Pago_Pago', label: 'Pago Pago'},
  {timezone: 'America/Adak', label: 'Adak'},
  {timezone: 'Pacific/Honolulu', label: 'Hawaii'},
  {timezone: 'Pacific/Marquesas', label: 'Marquesas'},
  {timezone: 'America/Anchorage', label: 'Anchorage'},
  {timezone: 'Pacific/Gambier', label: 'Gambier'},
  {timezone: 'America/Santa_Isabel', label: 'Santa Isabel'},
  {timezone: 'America/Los_Angeles', label: 'Pacific Time (US & Canada)'},
  {timezone: 'Pacific/Pitcairn', label: 'Pitcairn'},
  {timezone: 'America/Mazatlan', label: 'Mazatlan'},
  {timezone: 'America/Denver', label: 'Mountain Time (US & Canada)'},
  {timezone: 'America/Phoenix', label: 'Phoenix'},
  {timezone: 'America/Chicago', label: 'Central Time (US & Canada)'},
  {timezone: 'America/Mexico_City', label: 'Mexico City'},
  {timezone: 'America/Guatemala', label: 'Guatemala'},
  {timezone: 'Pacific/Easter', label: 'Easter Island'},
  {timezone: 'America/Bogota', label: 'Bogota'},
  {timezone: 'America/Havana', label: 'Havana'},
  {timezone: 'America/New_York', label: 'Eastern Time (US & Canada)'},
  {timezone: 'America/Campo_Grande', label: 'Campo Grande'},
  {timezone: 'America/Halifax', label: 'Halifax'},
  {timezone: 'America/Goose_Bay', label: 'Goose Bay'},
  {timezone: 'America/Santo_Domingo', label: 'Santo Domingo'},
  {timezone: 'America/Santiago', label: 'Santiago'},
  {timezone: 'America/Asuncion', label: 'Asuncion'},
  {timezone: 'America/Caracas', label: 'Caracas'},
  {timezone: 'America/St_Johns', label: 'St Johns'},
  {timezone: 'America/Argentina/Buenos_Aires', label: 'Buenos Aires'},
  {timezone: 'America/Sao_Paulo', label: 'Sao Paulo'},
  {timezone: 'Atlantic/Stanley', label: 'Stanley'},
  {timezone: 'America/Miquelon', label: 'Miquelon'},
  {timezone: 'America/Montevideo', label: 'Montevideo'},
  {timezone: 'America/Godthab', label: 'Godthab'},
  {timezone: 'America/Noronha', label: 'Noronha'},
  {timezone: 'Atlantic/Azores', label: 'Azores'},
  {timezone: 'Atlantic/Cape_Verde', label: 'Cape Verde'},
  {timezone: 'Europe/London', label: 'Dublin, Edinburgh, London'},
  {timezone: 'UTC', label: 'Greenwich Mean Time'},
  {timezone: 'Africa/Windhoek', label: 'Windhoek'},
  {timezone: 'Europe/Berlin', label: 'Amsterdam, Berlin, Paris'},
  {timezone: 'Africa/Lagos', label: 'West Central Africa'},
  {timezone: 'Asia/Gaza', label: 'Gaza'},
  {timezone: 'Asia/Beirut', label: 'Beirut'},
  {timezone: 'Asia/Damascus', label: 'Damascus'},
  {timezone: 'Africa/Cairo', label: 'Cairo'},
  {timezone: 'Asia/Jerusalem', label: 'Jerusalem'},
  {timezone: 'Africa/Johannesburg', label: 'Johannesburg'},
  {timezone: 'Asia/Baghdad', label: 'Baghdad'},
  {timezone: 'Europe/Moscow', label: 'Moscow'},
  {timezone: 'Europe/Minsk', label: 'Minsk'},
  {timezone: 'Europe/Istanbul', label: 'Istanbul'},
  {timezone: 'Asia/Tehran', label: 'Tehran'},
  {timezone: 'Asia/Yerevan', label: 'Yerevan'},
  {timezone: 'Asia/Baku', label: 'Baku'},
  {timezone: 'Asia/Dubai', label: 'Dubai'},
  {timezone: 'Asia/Kabul', label: 'Kabul'},
  {timezone: 'Asia/Karachi', label: 'Karachi'},
  {timezone: 'Asia/Yekaterinburg', label: 'Yekaterinburg'},
  {timezone: 'Asia/Kolkata', label: 'Kolkata'},
  {timezone: 'Asia/Kathmandu', label: 'Kathmandu'},
  {timezone: 'Asia/Dhaka', label: 'Dhaka'},
  {timezone: 'Asia/Omsk', label: 'Omsk'},
  {timezone: 'Asia/Rangoon', label: 'Rangoon'},
  {timezone: 'Asia/Krasnoyarsk', label: 'Krasnoyarsk'},
  {timezone: 'Asia/Jakarta', label: 'Jakarta'},
  {timezone: 'Asia/Shanghai', label: 'Manila, Perth, Shanghai'},
  {timezone: 'Asia/Irkutsk', label: 'Irkutsk'},
  {timezone: 'Australia/Eucla', label: 'Eucla'},
  {timezone: 'Asia/Tokyo', label: 'Osaka, Sapporo, Tokyo'},
  {timezone: 'Asia/Yakutsk', label: 'Yakutsk'},
  {timezone: 'Australia/Darwin', label: 'Darwin'},
  {timezone: 'Australia/Adelaide', label: 'Adelaide'},
  {timezone: 'Australia/Sydney', label: 'Canberra, Melbourne, Sydney'},
  {timezone: 'Australia/Brisbane', label: 'Brisbane'},
  {timezone: 'Asia/Vladivostok', label: 'Vladivostok'},
  {timezone: 'Australia/Lord_Howe', label: 'Lord Howe'},
  {timezone: 'Pacific/Noumea', label: 'Noumea'},
  {timezone: 'Pacific/Norfolk', label: 'Norfolk'},
  {timezone: 'Pacific/Fiji', label: 'Fiji'},
  {timezone: 'Pacific/Tarawa', label: 'Tarawa'},
  {timezone: 'Pacific/Majuro', label: 'Majuro'},
  {timezone: 'Pacific/Auckland', label: 'Auckland'},
  {timezone: 'Asia/Kamchatka', label: 'Kamchatka'},
  {timezone: 'Pacific/Chatham', label: 'Chatham'},
  {timezone: 'Pacific/Tongatapu', label: 'Tongatapu'},
  {timezone: 'Pacific/Apia', label: 'Apia'},
  {timezone: 'Pacific/Kiritimati', label: 'Kiritimati'}
];

export default sortBy(
  timezones.map(({timezone, label}) => ({
    value: timezone,
    offset: moment().tz(timezone).format('Z'),
    label
  })),
  (timezone) => parseInt(timezone.offset.replace(':', ''))
);
