import React, {Component} from 'react';
import ElementInput from './ElementInput';
import Select from '../Form/Select';
import Input from '../Form/Input';
import Button from '../Page/Button';

export default class AboutYouQuestion extends Component {
  handleSelectChange = (value) => {
    const {onChange} = this.props;
    onChange(value);
  };

  handleSelectSelect = (value) => {
    const {onChange} = this.props;

    onChange(value, true);
  };

  handleInputChange = ({target}) => {
    this.props.onChange(target.value);
  };

  render() {
    const {question, value, no, total, onNext} = this.props;
    const nextDisabled = !question.optional && !value;

    let input;

    switch (question.type) {
      case 'select':
        input = (
          <Select
            items={question.items}
            value={value}
            onSelect={this.handleSelectSelect}
            onChange={this.handleSelectChange}
            readOnly={question.readOnly}
            allowCustom={question.allowCustom}
            tabIndex={1}
          />
        );
        break;
      case 'input':
        input = (
          <Input
            placeholder={question.placeholder}
            value={value}
            className='w-full'
            tabIndex={1}
            onChange={this.handleInputChange}
          />
        );
        break;
      default:
        break;
    }

    return (
      <div className='relative overflow-hidden pb-35 lg:pb-73 pt-25 lg:pt-0'>
        <div className='small-type'>
          {no} / {total}
        </div>
        <div className='body-lg pt-17'>{question.label}</div>
        <div className='relative pt-30 flex flex-col lg:flex-row items-center'>
          <ElementInput className='flex-1 w-full'>{input}</ElementInput>
          <div className='flex-1 lg:flex-initial pl-16 lg:pl-36 pt-27 lg:pt-0 mr-5'>
            <Button
              label='Next'
              disabled={nextDisabled}
              onClick={onNext}
              tabIndex={2}
              onEnter={onNext}
            />
          </div>
        </div>
      </div>
    );
  }
}
