import React from 'react';
import saboteurs from '../../../const/saboteurs';
import SaboteurImage from '../../Page/SaboteurImage';
import ProgressBar from './ProgressBar';

const SaboteurResultBar = ({saboteur, score}) => (
  <div className='flex flex-row items-center'>
    <div className='flex-initial lg:w-245 flex flex-row items-center'>
      <SaboteurImage saboteur={saboteur} className='w-66 h-66 mr-20' />
      <div className='body hidden lg:block'>{saboteurs[saboteur]}</div>
    </div>
    <div className='flex-1'>
      <div className='lg:hidden body'>{saboteurs[saboteur]}</div>
      <ProgressBar position={score / 10} value={score} />
    </div>
  </div>
);

export default SaboteurResultBar;
