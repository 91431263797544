import React from 'react';
import Header from './Header';

const AssessmentFixedFooterPage = ({children, footer}) => (
  <div className='min-h-full'>
    <Header />
    {children}
    <div className='flex-initial fixed bottom-0 left-0 w-full'>{footer}</div>
  </div>
);

export default AssessmentFixedFooterPage;
