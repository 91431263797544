export default {
  avoider: 'Avoider',
  controller: 'Controller',
  hyper_achiever: 'Hyper-Achiever',
  hyper_rational: 'Hyper-Rational',
  hyper_vigilant: 'Hyper-Vigilant',
  pleaser: 'Pleaser',
  restless: 'Restless',
  stickler: 'Stickler',
  victim: 'Victim'
};
