import {observable} from 'mobx';

const CAPTCHA_V2_ELEMENT_ID = 'recaptcha_v2_element';

export default class CaptchaStore {
  @observable tokenV2 = '';

  setTokenV2(token) {
    this.tokenV2 = token;
  }

  async verifyCaptchaV2() {
    return new Promise((resolve) => {
      if (this.tokenV2) {
        resolve();
      }
      window.grecaptcha.render(CAPTCHA_V2_ELEMENT_ID, {
        sitekey: process?.env?.REACT_APP_CAPTCHA_V2_KEY,
        callback: (token) => {
          this.setTokenV2(token);
          resolve();
        }
      });
    });
  }

  async verifyCaptchaV3(actionName) {
    return new Promise((resolve) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process?.env?.REACT_APP_CAPTCHA_V3_KEY, {
            action: actionName
          })
          .then((token) => {
            resolve(token);
          });
      });
    });
  }
}
