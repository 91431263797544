import React from 'react';
import Link from './Link';
import sendEmailIcon from '../img/sendEmailIcon.svg';
import {usePreventFocusOnClick} from '../utils/usePreventFocusOnClick';

const ShareViaEmailButton = ({text, subject, body, className, iconClassname, onClick}) => {
  const handlePreventFocusOnClick = usePreventFocusOnClick();

  return (
    <Link
      className={`${className} focus:outline-blue rounded-6`}
      href={`mailto:?subject=${subject}&body=${body}`}
      onMouseDown={handlePreventFocusOnClick}
      onClick={onClick}
    >
      <img
        alt='copy icon'
        className={`inline-block mr-8 -mt-3 ${iconClassname}`}
        src={sendEmailIcon}
      />
      {text}
    </Link>
  );
};

export default ShareViaEmailButton;
