import _ from 'lodash';

const assessmentService = {
  getAnswersMap(store) {
    return _(store.answers).keyBy('question_id').mapValues('value').value();
  },

  getTotalQuestions({assessmentData: {from_app: fromApp}, questions, aboutYouQuestions}) {
    return fromApp ? questions.length : questions.length + aboutYouQuestions.length;
  },

  getQuestionByNo(store, no) {
    const {questions, aboutYouQuestions} = store;
    const activeElementIndex = no - 1;

    const isCurrent = (i) => i === activeElementIndex;

    const currentQuestion = questions.find((question, i) => isCurrent(i));
    const currentAboutYouQuestion = aboutYouQuestions.find((question, i) =>
      isCurrent(questions.length + i)
    );

    if (currentQuestion) {
      return {question: currentQuestion, id: currentQuestion.id, isAboutYou: false};
    }

    if (currentAboutYouQuestion) {
      return {
        question: currentAboutYouQuestion,
        id: currentAboutYouQuestion.field,
        isAboutYou: true
      };
    }
  },

  getAnswer(store, question) {
    const {assessmentData} = store;

    if (question.isAboutYou) {
      return assessmentData[question.question.field];
    }

    const answersMap = this.getAnswersMap(store);

    return answersMap[question.question.id];
  },

  getFirstUnansweredQuestionNo(store, ignoreAboutYou = false) {
    const {questions, aboutYouQuestions, assessmentData} = store;
    const totalQuestions = this.getTotalQuestions(store);
    const answersMap = this.getAnswersMap(store);
    const questionNo = questions.findIndex((q) => answersMap[q.id] === undefined);

    if (questionNo !== -1) {
      return questionNo + 1;
    }

    if (ignoreAboutYou) {
      return null;
    }

    const aboutYouQuestionNo = aboutYouQuestions.findIndex((q) => {
      return (
        !assessmentData[q.field] &&
        !q.optional &&
        (!q.shouldBeSkipped || !q.shouldBeSkipped(assessmentData))
      );
    });

    return aboutYouQuestionNo === -1 ? totalQuestions : questions.length + aboutYouQuestionNo + 1;
  },

  validateQuestionNo(store, questionNo) {
    const totalQuestions = this.getTotalQuestions(store);
    const firstUnansweredQuestionNo = this.getFirstUnansweredQuestionNo(store);

    if (
      firstUnansweredQuestionNo &&
      firstUnansweredQuestionNo !== questionNo &&
      questionNo > firstUnansweredQuestionNo
    ) {
      return firstUnansweredQuestionNo;
    }

    if (questionNo > totalQuestions) {
      return totalQuestions;
    }

    if (questionNo < 1) {
      return 1;
    }

    return null;
  },

  random(store) {
    store.questions.forEach((question) => {
      store.addAnswer(question.id, Math.round(Math.random() * 4));
    });
  },

  addAnswer(store, isAboutYou, questionId, value) {
    if (isAboutYou) {
      store.assessmentData[questionId] = value;
    } else {
      store.addAnswer(questionId, value);
    }
  },

  canGoNext(store, currentQuestionNo) {
    return (
      currentQuestionNo < this.getFirstUnansweredQuestionNo(store) &&
      currentQuestionNo < this.getTotalQuestions(store)
    );
  },

  canGoPrevious(store, currentQuestionNo) {
    return currentQuestionNo > 1;
  },

  getNextNo(store, currentQuestionNo) {
    const {questions, aboutYouQuestions, assessmentData} = store;

    if (currentQuestionNo < questions.length) {
      return currentQuestionNo + 1;
    }

    const nextAboutYouQuestionIndex = currentQuestionNo - questions.length;
    let skipped = 0;
    let nextQuestion = aboutYouQuestions[nextAboutYouQuestionIndex];

    while (nextQuestion && nextQuestion.shouldBeSkipped) {
      if (!nextQuestion.shouldBeSkipped(assessmentData)) {
        break;
      }

      skipped++;
      nextQuestion = aboutYouQuestions[nextAboutYouQuestionIndex + skipped];
    }

    return nextQuestion ? currentQuestionNo + skipped + 1 : null;
  },

  getPreviousNo(store, currentQuestionNo) {
    if (!this.canGoPrevious(store, currentQuestionNo)) {
      return null;
    }

    return currentQuestionNo - 1;
  }
};

export default assessmentService;
