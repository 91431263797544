import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import LoadingPage from '../LoadingPage';
import Button from '../Page/Button';
import AssessmentInfoPage from '../Page/AssessmentInfoPage';
import Vortex from './Vortex';

class AssessmentResultVortexPage extends Component {
  UNSAFE_componentWillMount() {
    const {
      pqStore,
      match: {
        params: {resultId}
      }
    } = this.props;

    pqStore.loadAssessmentResults(resultId);
  }

  render() {
    const {
      pqStore,
      history,
      match: {
        params: {resultId}
      }
    } = this.props;
    const {pending, data} = pqStore.result;
    const pq = Math.round(data.pq);
    const handleMoveToPqResultsTippingPointPage = () => {
      history.push(`/pq/results/${resultId}/tipping_point`);
    };

    if (pending) {
      return <LoadingPage />;
    }

    return (
      <AssessmentInfoPage
        image={<Vortex score={pq} />}
        title='Your Score in PQ Vortex'
        wide
        text={
          <>
            <p>
              Research has revealed a PQ score of approximately 75 as the{' '}
              <strong>tipping point</strong> for a net-positive vortex in one’s brain.
            </p>
            <p>
              Below the tipping point, one feels generally{' '}
              <strong>dragged down by a self-reinforcing cycle of negativity.</strong> The
              negativity might include stress, anxiety, self-doubt, anger, regret, shame, or guilt.
              Notice your score on the PQ Vortex.
            </p>
            <p>
              Above the tipping point, one feels generally{' '}
              <strong>uplifted by a contagious cycle of positivity</strong> inside the mind.
            </p>
          </>
        }
        button={
          <Button
            label='Why 75 as Tipping Point'
            tabIndex={0}
            className='w-full'
            onClick={handleMoveToPqResultsTippingPointPage}
            onEnter={handleMoveToPqResultsTippingPointPage}
          />
        }
      />
    );
  }
}

export default inject('pqStore')(observer(AssessmentResultVortexPage));
