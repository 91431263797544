import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import LoadingPage from '../LoadingPage';
import Button from '../Page/Button';
import ShareResults from './ShareResults';
import gaService from '../../ga.service';
import AssessmentInfoPage from '../Page/AssessmentInfoPage';

class AssessmentResultPage extends Component {
  UNSAFE_componentWillMount() {
    const {
      sabStore,
      match: {
        params: {resultId}
      }
    } = this.props;

    sabStore.loadAssessmentResults(resultId);
  }

  handleViewScoresClick = () => {
    const {
      history,
      match: {
        params: {resultId}
      }
    } = this.props;

    gaService.sendEvent('Saboteur Assessment', 'click', 'sab_scores');

    return history.push(`/saboteur/results/${resultId}/score`);
  };

  render() {
    const {pending, data} = this.props.sabStore.result;

    if (pending) {
      return <LoadingPage />;
    }

    return (
      <AssessmentInfoPage
        image={<div className='img-sab-results-intro' />}
        title={`${data.first_name}, Your Results are Ready!`}
        text={
          <>
            <p>
              We have scored your relative strength amongst 9 Saboteurs.
              <br />
              Discover the top Saboteurs that cause the greatest harm to both your performance and
              happiness.
            </p>
          </>
        }
        button={
          <>
            <Button
              onEnter={this.handleViewScoresClick}
              tabIndex={0}
              label='View Scores'
              onClick={this.handleViewScoresClick}
            />
            <br />
            <ShareResults />
          </>
        }
      />
    );
  }
}

export default inject('sabStore')(observer(AssessmentResultPage));
