import {observable, computed, action, transaction, autorun} from 'mobx';
import axios from 'axios';
import store from 'store';

const STORE_KEY = 'application_store';
const baseUrl = `${process.env.REACT_APP_API_URL}/coach_applications`;

export default class ApplicationStore {
  @observable submitted = false;
  @observable applicationToEdit = {
    pending: false,
    data: {}
  };

  constructor() {
    this.fromJson(store.get(STORE_KEY) || {});

    autorun(() => {
      store.set(STORE_KEY, this.asJson);
    });
  }

  @computed get asJson() {
    return {
      submitted: this.submitted
    };
  }

  fromJson(json) {
    this.submitted = !!json.submitted;
  }

  @action async submit(values) {
    await axios.post(baseUrl, values);

    this.submitted = true;
  }

  @action async update(updateSecret, values) {
    await axios.post(`${baseUrl}/${updateSecret}`, values);

    this.submitted = true;
  }

  @action async updatePod(updateSecret, values) {
    await axios.post(`${baseUrl}/${updateSecret}/pod`, values);

    this.submitted = true;
  }

  @action loadApplicationToEdit = (updateSecret) => {
    this.applicationToEdit.pending = true;

    return axios.get(`${baseUrl}/${updateSecret}`).then(({data}) =>
      transaction(() => {
        this.applicationToEdit.data = data;
        this.applicationToEdit.pending = false;
      })
    );
  };

  @action checkEmail = (updateSecret, email) => {
    return axios.get(`${baseUrl}/${updateSecret}/status`, {params: {email}}).then(({data}) => data);
  };
}
