import React from 'react';
import {inject, observer} from 'mobx-react';
import queryString from 'query-string';
import {Redirect, useLocation} from 'react-router-dom';

const EntryPage = ({pqStore}) => {
  const query = queryString.parse(useLocation().search);
  const {firstUnansweredQuestionNo} = pqStore;

  if (query.email) {
    pqStore.assessmentData.email = query.email;
  }

  [
    'company_id',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
    'lead_source'
  ].forEach((queryField) => {
    pqStore.assessmentData[queryField] = query[queryField] || null;
  });

  if (firstUnansweredQuestionNo && firstUnansweredQuestionNo !== 1) {
    return <Redirect to={`/pq/assessment/${firstUnansweredQuestionNo}`} />;
  }

  return <Redirect to='/pq/overview' />;
};

export default inject('pqStore')(observer(EntryPage));
