import React, {useLayoutEffect, useCallback, useState} from 'react';
import {inject, observer} from 'mobx-react';
import {Formik} from 'formik';
import compact from 'lodash/compact';
import toLower from 'lodash/toLower';
import Loading from '../Loading';
import LoadingPage from '../LoadingPage';
import Button from '../Page/Button';
import FormError from '../Form/FormError';
import ApplicationPodForm, {validateForm} from './ApplicationPodForm';
import AssessmentCenteredPage from '../Page/AssessmentCenteredPage';

const checkingEmails = {};

const checkEmail = (applicationStore, updateSecret, data) => async (_email) => {
  if (!_email) {
    return;
  }

  const email = toLower(_email);

  if (email === toLower(data.email)) {
    return 'Your email address is already part of the Pod';
  }

  const existingEmails = ((data.pod && data.pod.emails) || []).map(toLower);

  if (existingEmails.includes(email)) {
    return;
  }

  if (!checkingEmails[email]) {
    checkingEmails[email] = applicationStore.checkEmail(updateSecret, email);
  }

  const {status} = await checkingEmails[email];

  switch (status) {
    case 'not_found':
      return 'An application from this email address has not been found';
    case 'approved_pod_formed':
      return 'The application for this email address is already in another pod';
    case 'rejected':
      return 'The application for this email address is not approved';
    case 'approved_pod_pending':
      return '';
    default:
      return 'The application for this email address has not been approved yet';
  }
};

const renderMessage = (title, message) => (
  <AssessmentCenteredPage>
    <h2 className='heading-3'>{title}</h2>
    <div className='body mt-40'>{message}</div>
  </AssessmentCenteredPage>
);

const UpdateApplicationPodPage = ({
  applicationStore,
  match: {
    params: {updateSecret}
  }
}) => {
  const [submitted, setSubmitted] = useState(false);

  useLayoutEffect(() => {
    applicationStore.loadApplicationToEdit(updateSecret);
  }, [applicationStore, updateSecret]);
  const {pending, data} = applicationStore.applicationToEdit;

  const validate = useCallback(
    async (values) => validateForm(values, checkEmail(applicationStore, updateSecret, data)),
    [applicationStore, updateSecret, data]
  );

  const handleSubmitForm = async (values, actions) => {
    try {
      await applicationStore.updatePod(updateSecret, {
        title: values.title,
        emails: compact(values.emails)
      });
      setSubmitted(true);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        actions.setErrors({form: err.response.data.message});
      }
    }
  };

  if (pending) {
    return <LoadingPage />;
  }

  const isNew = !data.pod || !data.pod.title;
  const isLeader = data.is_leader;

  if (data.pod && !isLeader) {
    return renderMessage(
      'Not Available',
      `You are already a member of the Pod: "${data.pod.title}"`
    );
  }

  if (data.status === 'rejected') {
    return renderMessage('Not Available', `Your application is not approved.`);
  }

  if (
    data.status !== 'approved_pod_pending' &&
    !(data.status === 'approved_pod_formed' && isLeader)
  ) {
    return renderMessage(
      'Not Available',
      `Your application is not approved yet. Please check back soon.`
    );
  }

  if (submitted) {
    return renderMessage(
      'Success',
      isNew ? 'Your Pod is successfully created' : 'Your Pod is successfully updated'
    );
  }

  const initialValues = isNew
    ? {
        email: data.email,
        title: '',
        emails: ['', '']
      }
    : {
        email: data.email,
        title: data.pod.title,
        emails: data.pod.emails.map(String)
      };

  return (
    <AssessmentCenteredPage>
      <h2 className='heading-4 lg:heading-3'>{isNew ? 'Create your Pod' : 'Update your Pod'}</h2>
      <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmitForm}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          submitForm,
          isSubmitting,
          setFieldValue,
          submitCount
        }) => (
          <div className='body'>
            <form onSubmit={handleSubmit}>
              {isSubmitting ? <Loading /> : null}
              <ApplicationPodForm
                {...{
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  setFieldValue
                }}
              />

              {errors.form && !!submitCount ? (
                <FormError className='mt-30'>{errors.form}</FormError>
              ) : null}
              <Button
                className='mt-20'
                label={isNew ? 'Complete Pod' : 'Update Pod'}
                onClick={submitForm}
                disabled={isSubmitting}
              />
            </form>
          </div>
        )}
      </Formik>
    </AssessmentCenteredPage>
  );
};

export default inject('applicationStore')(observer(UpdateApplicationPodPage));
