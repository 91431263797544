import {Component} from 'react';
import {withRouter} from 'react-router-dom';
import gaService from './../ga.service.js';

class Analytics extends Component {
  componentDidMount() {
    this.sendPageView(this.props.location);
    this.props.history.listen(this.sendPageView);
  }

  sendPageView(location) {
    gaService.pageView(location.pathname + location.search);
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(Analytics);
