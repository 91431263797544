import React, {useState} from 'react';
import {observer} from 'mobx-react';
import Button from './Page/Button';
import Input from './Form/Input';
import FormError from './Form/FormError';
import Container from './Page/Container';
import emailRegex from '../const/emailRegex';
import {useActionOnEnterKeyDownHandler} from '../utils/useActionOnEnterKeyDownHandler';
import {usePreventFocusOnClick} from '../utils/usePreventFocusOnClick';
import {isRestrictedEmail} from '../utils/isRestrictedEmail';
import jobStatuses from '../const/jobStatuses';
import signUpNameRegex from '../const/signUpNameRegex';

const SignUpForm = ({onSignUp, data, checkRestricted}) => {
  const [restrictedEmail, setRestrictedEmail] = useState(false);
  const isB2B =
    !data.is_referred_by_coach &&
    !data.is_coaching &&
    [jobStatuses.business_leader, jobStatuses.employed].includes(data.job_status);

  const handleSignUp = () => {
    let invalid = false;

    if (!data.first_name) {
      data.invalid_fields.first_name = invalid = true;
    }

    if (!data.email || !emailRegex.test(data.email)) {
      data.invalid_fields.email = invalid = true;
    }

    if (checkRestricted && data.email && isRestrictedEmail(data.email)) {
      setRestrictedEmail((invalid = true));
    }

    if (invalid) {
      return;
    }

    return onSignUp().catch((err) => {
      if ([400, 403].includes(err?.response?.status) && err?.response?.data?.message) {
        data.form_error = err.response.data.message;
      } else {
        throw err;
      }
    });
  };

  const handleFirstNameChange = ({target}) => {
    const value = target.value.replace(signUpNameRegex, '');

    data.first_name = value;
    data.invalid_fields.first_name = !value;
  };

  const handleLastNameChange = ({target}) => {
    data.last_name = target.value.replace(signUpNameRegex, '');
  };

  const handleEmailChange = ({target}) => {
    data.email = target.value;
    if (data.touched_fields.email) {
      data.invalid_fields.email = !target.value || !emailRegex.test(target.value);
      setRestrictedEmail(data.email && isRestrictedEmail(data.email));
    }

    // reset form error
    data.form_error = '';
  };

  const handleEmailBlur = ({target}) => {
    data.email = target.value;
    data.touched_fields.email = true;
    data.invalid_fields.email = !target.value || !emailRegex.test(target.value);
  };

  const handleChangeSyncInput = () => {
    data.to_sync = !data.to_sync;
  };

  const handleChangeConfirmationInput = () => {
    data.send_confirmation_email = !data.send_confirmation_email;
  };

  const handleSyncEnterKeyDown = useActionOnEnterKeyDownHandler(handleChangeSyncInput);

  const handleConfirmationEnterKeyDown = useActionOnEnterKeyDownHandler(
    handleChangeConfirmationInput
  );

  const handlePreventFocusOnClick = usePreventFocusOnClick();

  return (
    <div className='flex-1 flex justify-center'>
      <div className='flex-1 flex flex-col max-w-460 lg:max-w-600'>
        <div className='flex-1 flex flex-col justify-center'>
          <Container>
            <h3 className='heading-4 lg:heading-3'>Congratulations!</h3>
            <div className='body pt-21 lg:pt-40'>
              You’ve completed the assessment.
              <br />
              Let us know how to share your results with you.
            </div>
            <div className='mt-60 mb-30'>
              <Input
                className='mt-28'
                label='First Name*'
                type='text'
                value={data.first_name}
                error={data.invalid_fields.first_name ? 'This field is required' : null}
                onChange={handleFirstNameChange}
              />
              <Input
                className='mt-28'
                label='Last Name'
                type='text'
                value={data.last_name}
                onChange={handleLastNameChange}
              />
              <Input
                className='mt-28'
                label={(isB2B ? 'Business email ' : 'Email ') + 'address*'}
                type='email'
                value={data.email}
                error={
                  data.invalid_fields.email
                    ? `This field should be a valid ${isB2B ? 'business' : ''} email address`
                    : null
                }
                hint='Please enter your email address. We never share your results with anyone.'
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
              />
            </div>
            {isB2B && (
              <>
                <div className='mt-10 mb-30 xsmall-type-medium text-black-57'>
                  Learn about business applications of Positive Intelligence by providing your
                  business email address.
                </div>
              </>
            )}
            {restrictedEmail && (
              <FormError className='mb-30'>
                For a more reliable delivery of results, we recommend using your personal email
                address. Some corporate or institutional email systems might block or filter our
                emails.
              </FormError>
            )}
            <div id='recaptcha_v2_element' className='mb-20' />
            {data.form_error && <FormError>{data.form_error}</FormError>}
            <Button
              className='mt-20 w-full'
              label='Send me my assessment results'
              onClick={handleSignUp}
              onEnter={handleSignUp}
              tabIndex={0}
            />
            <div className='xsmall-type-medium mt-15 text-black-57 text-center'>
              When you sign up, we’ll keep you updated with periodic emails. <br />
              By completing this survey, you are agreeing to our{' '}
              <a
                href='https://www.positiveintelligence.com/terms-conditions/'
                target='_blank'
                rel='noreferrer'
                onMouseDown={handlePreventFocusOnClick}
                className='underline cursor-pointer focus:outline-blue rounded-6'
              >
                Terms and Conditions
              </a>{' '}
              and{' '}
              <a
                href='https://www.positiveintelligence.com/privacy-policy/'
                target='_blank'
                rel='noreferrer'
                onMouseDown={handlePreventFocusOnClick}
                className='underline cursor-pointer focus:outline-blue rounded-6'
              >
                Privacy Policy.
              </a>
            </div>
            {process.env.REACT_APP_DEBUG_INFUSIONSOFT === '1' ? (
              <div className='text-black-57 body-caption mt-30'>
                <input
                  id='to_sync'
                  type='checkbox'
                  className='focus:outline-blue'
                  checked={!!data.to_sync}
                  onMouseDown={handlePreventFocusOnClick}
                  onChange={handleChangeSyncInput}
                  onKeyDown={handleSyncEnterKeyDown}
                />{' '}
                <label className='text-black-57' htmlFor='to_sync'>
                  Send to Infusionsoft / Hubspot
                </label>
                {'\u00A0'}
                {'\u00A0'}
                {'\u00A0'}
                <input
                  id='send_confirmation_email'
                  type='checkbox'
                  className='focus:outline-blue'
                  checked={!!data.send_confirmation_email}
                  onChange={handleChangeConfirmationInput}
                  onMouseDown={handlePreventFocusOnClick}
                  onKeyDown={handleConfirmationEnterKeyDown}
                />{' '}
                <label className='text-black-57' htmlFor='send_confirmation_email'>
                  Send confirmation code
                </label>
              </div>
            ) : null}
          </Container>
        </div>
        <div className='xsmall-type-medium mb-30 mt-50 px-30 lg:px-100 text-black-57 text-center'>
          This site is protected by reCAPTCHA and the Google{' '}
          <a
            href='https://www.google.com/intl/en/policies/terms/'
            target='_blank'
            rel='noreferrer'
            onMouseDown={handlePreventFocusOnClick}
            className='underline cursor-pointer focus:outline-blue rounded-6'
          >
            Terms of Service
          </a>{' '}
          and{' '}
          <a
            href='https://www.google.com/intl/en/policies/privacy/'
            target='_blank'
            rel='noreferrer'
            onMouseDown={handlePreventFocusOnClick}
            className='underline cursor-pointer focus:outline-blue rounded-6'
          >
            Privacy Policy
          </a>{' '}
          apply.
        </div>
      </div>
    </div>
  );
};

export default observer(SignUpForm);
