import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import LoadingPage from '../LoadingPage';
import Button from '../Page/Button';
import AssessmentTextPage from '../Page/AssessmentTextPage';

class AssessmentResultScorePage extends Component {
  UNSAFE_componentWillMount() {
    const {
      pqStore,
      match: {
        params: {resultId}
      }
    } = this.props;

    pqStore.loadAssessmentResults(resultId);
  }

  renderDescription(pq) {
    if (pq >= 90) {
      return (
        <>
          <p>
            The flow or <strong>“in the zone” experience is not constant.</strong> Just as any
            black-belt athlete would continue to practice both the muscles and the techniques, you’d
            want to do the same. This ensures{' '}
            <strong>maintenance of current mental muscle strength,</strong> as all muscles are
            subject to atrophy if not exercised.
          </p>
          <p>
            At your high level of mental fitness, you might also consider deepening your
            understanding of PQ principles and techniques, so that you can{' '}
            <strong>help others boost their own mental fitness.</strong> Most people need and want
            the benefits of higher PQ, but don’t know how to get there without concrete tools and
            techniques.
          </p>
        </>
      );
    }

    if (pq >= 80) {
      return (
        <>
          <p>
            Just as any black-belt athlete would continue to practice both the muscles and the
            techniques, you’d want to do the same. This ensures{' '}
            <strong>maintenance of current mental muscle strength,</strong> as all muscles are
            subject to atrophy if not exercised.
          </p>
          <p>
            Given that you’re relatively close to the tipping point, if your mental fitness muscles
            atrophy to below the tipping point, you’d experience a{' '}
            <strong>disproportional and significant loss.</strong>
          </p>
          <p>
            Imagine what would happen to your performance and joy if you shifted from swimming with
            the current to swimming against the current. That’s similar to switching from the
            net-positive mode of your brain, mediated with <strong>endorphins</strong> to the
            net-negative, mediated by the stress hormone <strong>Cortisol.</strong>
          </p>
          <p>
            At your high level of mental fitness, you might also consider deepening your
            understanding of PQ principles and techniques, so that you can{' '}
            <strong>help others boost their own mental fitness.</strong> Most people need and want
            the benefits of higher PQ, but don’t know how to get there without concrete tools and
            techniques.
          </p>
        </>
      );
    }

    if (pq >= 71) {
      return (
        <>
          <p>
            As tipping point implies, you stand to gain the most from improving your PQ. Getting
            solidly above the tipping point results in{' '}
            <strong>disproportional positive impacts</strong> on your performance, happiness, and
            relationships.
          </p>
          <p>
            Your current experience is like <strong>switching back and forth</strong> between
            swimming with the current and against the current. Imagine what would happen to your
            performance and joy if you shifted to consistently swimming with the current. You’d feel{' '}
            <strong>continually uplifted</strong> by the net-positive forces within your mind that
            become self-reinforcing and contagious to others around you.
          </p>
          <p>
            Conversely, you also stand to <strong>lose the most</strong> from not improving your PQ.
            Your mental fitness muscles, like physical muscles, are{' '}
            <strong>subject to atrophy</strong> if you don’t actively work on maintaining or growing
            them. Slipping below the tipping point would have a{' '}
            <strong>disproportionate negative impact</strong> on your performance, happiness, and
            relationships.
          </p>
          <p>
            Remember the persistent net-negative drag is orchestrated by the stress-hormone{' '}
            <strong>Cortisol,</strong> as opposed to <strong>endorphins</strong> orchestrating the
            uplifting net-positive vortex. The payoff for exercising your mental fitness muscles is{' '}
            <strong>disproportionally high.</strong>
          </p>
        </>
      );
    }

    if (pq >= 50) {
      return (
        <>
          <p>
            Imagine what would happen to your performance and joy if you shifted from swimming
            against the current to swimming with the current. That’s the kind of{' '}
            <strong>dramatic improvement</strong> you’d experience if you improved your mental
            fitness (PQ) beyond the tipping point.
          </p>
          <p>
            You’d switch from feeling constantly dragged down by the net-negative currents of your
            mind, to feeling <strong>uplifted by the net-positive.</strong> Your experience of work
            and life’s challenges and relationships would become much more harmonious.{' '}
            <strong>You’d produce more with less effort.</strong> And you’d enjoy every step along
            the journey, rather than short-lived celebrations after the occasional wins.
          </p>
          <p>
            Since you’re close to the tipping point and generally positive, you might have convinced
            yourself that all your current stress, anxieties, frustrations, conflicts, and hardships
            are just “normal” and inevitable in life.
          </p>
          <p>
            Above the tipping point, you’ll realize that actually your greatest accomplishments come
            not when you’re working “hard” but when you’re able to experience the{' '}
            <strong>ease and flow of the net-positive vortex.</strong> That’s what athletes report
            about the effortless mastery of being “in the zone.”
          </p>
        </>
      );
    }

    if (pq >= 25) {
      return (
        <>
          <p>
            Imagine what would happen to your performance and joy if you shifted from swimming
            against the current to swimming with the current. That’s the kind of{' '}
            <strong>dramatic improvement</strong> you’d experience if you improved your mental
            fitness (PQ) beyond the tipping point.
          </p>
          <p>
            You’d switch from feeling constantly dragged down by the net-negative currents of your
            mind, to feeling <strong>uplifted by the net-positive.</strong> Your experience of work
            and life’s challenges and relationships would become much more harmonious.{' '}
            <strong>You’d produce more with less effort.</strong> And you’d enjoy every step along
            the journey, rather than short-lived celebrations after the occasional wins.
          </p>
          <p>
            You’d be uplifted by <strong>endorphins</strong> coursing through your brain rather than
            the stress hormone <strong>Cortisol.</strong>
          </p>
          <p>
            In your current mindset you might find it <strong>hard to believe</strong> that such a
            shift would be possible for you. This is the vicious cycle of the negative voices in
            your head producing and reinforcing their version of reality.
          </p>
          <p>
            Listen to the <strong>quiet whisper of wisdom</strong> in your mind, the voice that
            knows you’re both capable and deserving of a life with greater happiness, less stress,
            more harmonious relationships, and more meaningful accomplishments.
          </p>
          <p>
            Your mental fitness muscles, like any muscle, will grow with practice.{' '}
            <strong>You absolutely can grow those muscles</strong> beyond the tipping point and
            experience a dramatic shift in your experience of life.
          </p>
        </>
      );
    }

    return (
      <>
        <p>
          If you don’t find the steps outlined in this program to be enough to help you shift,
          please seek professional help.
        </p>
        <p>
          Imagine what would happen to your performance and joy if you shifted from swimming against
          the current to swimming with the current. That’s the kind of{' '}
          <strong>dramatic improvement</strong> you’d experience if you improved your mental fitness
          (PQ) beyond the tipping point.
        </p>
        <p>
          You’d switch from feeling constantly dragged down by the net-negative currents of your
          mind, to feeling <strong>uplifted by the net-positive.</strong> Your experience of work
          and life’s challenges and relationships would become much more harmonious.{' '}
          <strong>You’d produce more with less effort.</strong> And you’d enjoy every step along the
          journey, rather than short-lived celebrations after the occasional wins.
        </p>
        <p>
          You’d be uplifted by <strong>endorphins</strong> coursing through your brain rather than
          the stress hormone <strong>Cortisol.</strong>
        </p>
        <p>
          In your current mindset you might find it <strong>hard to believe</strong> that such a
          shift would be possible for you. This is the vicious cycle of the negative voices in your
          head producing and reinforcing their version of reality.
        </p>
        <p>
          Listen to the <strong>quiet whisper of wisdom</strong> in your mind, the voice that knows
          you’re both capable and deserving of a life with greater happiness, less stress, more
          harmonious relationships, and more meaningful accomplishments.
        </p>
        <p>
          Your mental fitness muscles, like any muscle, will grow with practice.{' '}
          <strong>You absolutely can grow those muscles</strong> beyond the tipping point and
          experience a dramatic shift in your experience of life.
        </p>
      </>
    );
  }

  render() {
    const {
      pqStore,
      history,
      match: {
        params: {resultId}
      }
    } = this.props;
    const {pending, data} = pqStore.result;
    const pq = Math.round(data.pq);
    const handleMoveToPqResultsBoostPage = () => {
      history.push(`/pq/results/${resultId}/boost`);
    };

    if (pending) {
      return <LoadingPage />;
    }

    return (
      <AssessmentTextPage>
        <h3 className='heading-4 lg:heading-3'>Why Boost My PQ</h3>
        <div className='body pt-21 lg:pt-40'>{this.renderDescription(pq)}</div>
        <Button
          label='How to Boost My PQ'
          tabIndex={0}
          className='mt-30'
          onClick={handleMoveToPqResultsBoostPage}
          onEnter={handleMoveToPqResultsBoostPage}
        />
      </AssessmentTextPage>
    );
  }
}

export default inject('pqStore')(observer(AssessmentResultScorePage));
