import React from 'react';
import {usePreventFocusOnClick} from '../../utils/usePreventFocusOnClick';

const Footer = ({className = ''}) => {
  const handlePreventFocusOnClick = usePreventFocusOnClick();

  return (
    <div className={`body-footer ${className}`}>
      <a
        href='https://www.positiveintelligence.com/terms-conditions/'
        target='_blank'
        rel='noreferrer'
        onMouseDown={handlePreventFocusOnClick}
        className='mr-30 lg:mr-40 cursor-pointer focus:outline-blue rounded-6 xsmall-type-medium'
      >
        Terms & Conditions
      </a>
      <a
        href='https://www.positiveintelligence.com/privacy-policy/'
        target='_blank'
        rel='noreferrer'
        onMouseDown={handlePreventFocusOnClick}
        className='cursor-pointer focus:outline-blue rounded-6 xsmall-type-medium'
      >
        Privacy Policy
      </a>
    </div>
  );
};
export default Footer;
