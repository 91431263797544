/* eslint no-unused-expressions: 0 */
import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import LoadingPage from '../LoadingPage';
import SavedResult from '../SavedResult';

class SavedResultsPage extends Component {
  state = {
    redirecting: false
  };

  UNSAFE_componentWillMount() {
    const {
      sabStore,
      match: {
        params: {resultId}
      }
    } = this.props;

    // hook for Android PQ App
    window.Android?.finishAssessment?.();

    // hook for PWA
    window.parent?.postMessage?.('finish_assessment', '*');

    sabStore.loadAssessmentResults(resultId);
  }

  render() {
    const {
      history,
      sabStore,
      match: {
        params: {resultId}
      }
    } = this.props;
    const {redirecting} = this.state;
    const {pending} = sabStore.result;

    if (pending || redirecting) {
      return <LoadingPage />;
    }

    return (
      <SavedResult
        history={history}
        email={sabStore.result.data.email}
        clientName={sabStore.result.data.first_name}
        clientType={sabStore.result.data.client_type}
        updateLink={`/saboteur/update/${resultId}`}
        tempLink={`/saboteur/results/${sabStore.result.data.temp_link_id}`}
        withLeftBlockContent
      />
    );
  }
}

export default inject('sabStore')(observer(SavedResultsPage));
