import React, {useCallback, useLayoutEffect} from 'react';
import {inject, observer} from 'mobx-react';
import Assessment from '../Assessment/Assessment';
import AboutYouQuestion from '../Assessment/AboutYouQuestion';
import Question from '../Assessment/Question';
import gaService from '../../ga.service';
import AssessmentSurveyPage from '../Page/AssessmentSurveyPage';
import assessmentService from '../../model/assessmentService';

const AssessmentPage = ({history, match, sabStore}) => {
  const questionNo = parseInt(match.params.questionNo, 10);
  const totalQuestions = assessmentService.getTotalQuestions(sabStore);
  const labels = ['Strongly\nDisagree', '', 'Neutral', '', 'Strongly\nAgree'];

  const question = assessmentService.getQuestionByNo(sabStore, questionNo);

  useLayoutEffect(() => {
    const correctQuestionNo = assessmentService.validateQuestionNo(sabStore, questionNo);

    if (correctQuestionNo !== null) {
      return history.replace(`/saboteur/assessment/${correctQuestionNo}`);
    }
    // the deps are empty intentionally to run the effect only once
    // eslint-disable-next-line
  }, []);

  const handleRandomDebug = useCallback(() => {
    assessmentService.random(sabStore);

    return history.push(
      `/saboteur/assessment/${assessmentService.getFirstUnansweredQuestionNo(sabStore)}`
    );
  }, [history, sabStore]);

  const handleNext = useCallback(() => {
    const nextNo = assessmentService.getNextNo(sabStore, questionNo);

    if (nextNo !== null) {
      history.push(`/saboteur/assessment/${nextNo}`);
    } else {
      history.push(`/saboteur/save`);
    }
  }, [history, sabStore, questionNo]);

  const handlePrevious = useCallback(() => {
    const previousNo = assessmentService.getPreviousNo(sabStore, questionNo);

    if (previousNo !== null) {
      return history.push(`/saboteur/assessment/${previousNo}`);
    }
  }, [history, sabStore, questionNo]);

  const handleQuestionAnswer = useCallback(
    (value, goNext) => {
      gaService.sendEvent('Saboteur Assessment', 'click', `sab_${questionNo}`);

      const {isAboutYou, id} = question;

      assessmentService.addAnswer(sabStore, isAboutYou, id, value);

      if (goNext) {
        requestAnimationFrame(handleNext);
      }
    },
    [questionNo, sabStore, question, handleNext]
  );

  const handleAboutYouQuestionAnswerNext = useCallback(() => {
    gaService.sendEvent('Saboteur Assessment', 'click', `sab_${questionNo}`);

    requestAnimationFrame(handleNext);
  }, [handleNext, questionNo]);

  if (!question) {
    return null;
  }

  const header = question.isAboutYou ? 'About you' : '';

  const subheader = question.isAboutYou
    ? 'In order to provide you with relevant insights and future updates, please tell us:'
    : '';

  return (
    <AssessmentSurveyPage
      onRandomDebug={process.env.REACT_APP_DEBUG_INFUSIONSOFT === '1' ? handleRandomDebug : null}
      onNext={handleNext}
      onPrevious={handlePrevious}
      canGoNext={assessmentService.canGoNext(sabStore, questionNo)}
      canGoPrevious={assessmentService.canGoPrevious(sabStore, questionNo)}
      questionNo={questionNo}
      totalQuestions={totalQuestions}
    >
      <Assessment
        className='flex-1'
        questionNo={questionNo}
        header={header}
        subheader={subheader}
        question={
          question.isAboutYou ? (
            <AboutYouQuestion
              key={question.id}
              no={questionNo}
              total={totalQuestions}
              question={question.question}
              value={assessmentService.getAnswer(sabStore, question)}
              onChange={handleQuestionAnswer}
              onNext={handleAboutYouQuestionAnswerNext}
            />
          ) : (
            <Question
              key={question.id}
              no={questionNo}
              total={totalQuestions}
              question={question.question}
              labels={labels}
              answer={assessmentService.getAnswer(sabStore, question)}
              onAnswer={handleQuestionAnswer}
            />
          )
        }
      />
    </AssessmentSurveyPage>
  );
};

export default inject('sabStore')(observer(AssessmentPage));
