import React, {useState, useEffect, useRef} from 'react';
import copy from 'copy-to-clipboard';
import {createPopper} from '@popperjs/core';
import Link from './Link';
import copyIcon from '../img/copyIcon.svg';
import {useActionOnEnterKeyDownHandler} from '../utils/useActionOnEnterKeyDownHandler';
import {usePreventFocusOnClick} from '../utils/usePreventFocusOnClick';

const ShareViaCopyButton = ({text, textToCopy, tabIndex, className, iconClassname, onClick}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const popper = useRef(null);
  const button = useRef(null);
  const tooltip = useRef(null);
  const handlePreventFocusOnClick = usePreventFocusOnClick();

  useEffect(() => {
    if (showTooltip) {
      setTimeout(() => popper.current && popper.current.update(), 1);
      const timer = setTimeout(() => setShowTooltip(false), 4000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showTooltip]);

  useEffect(() => {
    popper.current = createPopper(button.current, tooltip.current, {
      placement: 'top',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 6]
          }
        }
      ]
    });
  }, []);

  const handleSetTextToClipboard = () => {
    setShowTooltip(true);
    copy(textToCopy);
    onClick && onClick();
  };

  return (
    <>
      <div
        ref={tooltip}
        className={`py-8 px-16 bg-black text-white transition-opacity duration-300 ${
          showTooltip ? 'visible opacity-75' : 'hidden opacity-0'
        }`}
      >
        Link Copied
      </div>
      <Link
        ref={button}
        tabIndex={tabIndex}
        className={`${className} focus:outline-blue rounded-6`}
        onClick={handleSetTextToClipboard}
        onMouseDown={handlePreventFocusOnClick}
        onKeyDown={useActionOnEnterKeyDownHandler(handleSetTextToClipboard)}
      >
        <img alt='copy icon' className={`inline-block mr-4 ${iconClassname}`} src={copyIcon} />
        {text}
      </Link>
    </>
  );
};

export default ShareViaCopyButton;
