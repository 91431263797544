import React from 'react';
import AssessmentInfoPage from '../Page/AssessmentInfoPage';
import Button from '../Page/Button';
import gaService from '../../ga.service';

const OverviewPage = ({history}) => {
  const handleMoveToAssessment = () => {
    gaService.sendEvent('Saboteur Assessment', 'click', 'sab_begin');
    history.push(`/saboteur/assessment/1`);
  };

  return (
    <AssessmentInfoPage
      image={<div className='img-assessment-instructions' />}
      title='Saboteur Assessment Instructions'
      text={
        <>
          Move quickly and go with the first response that comes to your mind. Don't worry about
          being consistent when you notice similar or overlapping questions.
        </>
      }
      button={
        <Button
          label='Start'
          tabIndex={0}
          onClick={handleMoveToAssessment}
          onEnter={handleMoveToAssessment}
        />
      }
    />
  );
};

export default OverviewPage;
