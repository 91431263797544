import React, {useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import SignUpForm from '../SignUpForm';
import gaService from '../../ga.service';
import AssessmentBlankPage from '../Page/AssessmentBlankPage';
import assessmentService from '../../model/assessmentService';
import Loading from '../Loading';

const SignUpPage = ({
  history,
  sabStore,
  match: {
    params: {resultId}
  }
}) => {
  useEffect(() => {
    if (resultId) {
      sabStore.loadAssessmentResults(resultId).then(() => {
        sabStore.toBeUpdated();
      });
    } else {
      const unansweredQuestionNo = assessmentService.getFirstUnansweredQuestionNo(sabStore, true);
      if (unansweredQuestionNo !== null) {
        return history.replace(`/saboteur/assessment/${unansweredQuestionNo}`);
      }
    }
  }, [history, resultId, sabStore]);

  const handleSignUp = () => {
    gaService.sendEvent('Saboteur Assessment', 'click', 'sab_result');

    return sabStore.saveAssessmentResultsData().then(() => {
      const {result} = sabStore;

      if (result.data.confirmed) {
        return history.push(`/saboteur/saved/${result.id}`);
      } else {
        return history.push(`/saboteur/confirm/${result.id}`);
      }
    });
  };

  return (
    <AssessmentBlankPage>
      {sabStore.result.pending ? <Loading fixed /> : null}
      <SignUpForm data={sabStore.assessmentData} onSignUp={handleSignUp} />
    </AssessmentBlankPage>
  );
};

export default inject('sabStore')(observer(SignUpPage));
