import React from 'react';
import Container from './Container';
import PQLogo from '../PQLogo';
import Progress from '../Assessment/Progress';
import Footer from './Footer';
import arrowUp from '../../img/arrowUp.svg';
import arrowDown from '../../img/arrowDown.svg';
import {useActionOnEnterKeyDownHandler} from '../../utils/useActionOnEnterKeyDownHandler';
import {usePreventFocusOnClick} from '../../utils/usePreventFocusOnClick';

const AssessmentSurveyPage = ({
  children,
  onRandomDebug,
  canGoNext,
  canGoPrevious,
  onPrevious,
  onNext,
  questionNo,
  totalQuestions
}) => {
  const handlePreventFocusOnClick = usePreventFocusOnClick();

  return (
    <div className='flex flex-col items-center relative min-h-full'>
      {onRandomDebug ? (
        <div className='absolute top-0 left-0 text-black-57'>
          <div className='body-footer cursor-pointer' onClick={onRandomDebug}>
            RANDOM
          </div>
        </div>
      ) : null}
      <Container className='flex-initial w-full pt-30 lg:pt-50'>
        <PQLogo />
      </Container>
      {children}
      <div className='absolute right-30 lg:right-42 bottom-74 lg:bottom-1/2 lg:top-1/2 lg:-translate-y-1/2 flex flex-row lg:flex-col'>
        <div
          className={`mb-0 mr-30 lg:mb-40 lg:mr-0 focus:outline-blue rounded-6 ${
            canGoPrevious ? 'cursor-pointer' : 'opacity-30'
          }`}
          onClick={onPrevious}
          onMouseDown={handlePreventFocusOnClick}
          onKeyDown={useActionOnEnterKeyDownHandler(onPrevious)}
          tabIndex={-1}
          role='button'
          disabled={!canGoPrevious ? 'disabled' : ''}
          aria-label='Previous question'
        >
          <img src={arrowUp} alt='' />
        </div>
        <div
          className={`focus:outline-blue rounded-6 ${canGoNext ? 'cursor-pointer' : 'opacity-30'}`}
          onClick={onNext}
          onMouseDown={handlePreventFocusOnClick}
          onKeyDown={useActionOnEnterKeyDownHandler(onNext)}
          tabIndex={-1}
          disabled={!canGoNext ? 'disabled' : ''}
          role='button'
          aria-label='Next question'
        >
          <img src={arrowDown} alt='' />
        </div>
      </div>
      <Container className='flex-initial flex flex-col lg:flex-row lg:items-center w-full border-t border-solid border-black-lines'>
        <div className='flex-1 pb-4 pt-33 lg:py-0'>
          <div className='max-w-220 lg:max-w-full lg:w-300 pr-100 box-content lg:pr-0'>
            <Progress current={questionNo} total={totalQuestions} />
          </div>
        </div>
        <div className='flex-initial py-28 lg:pb-46 lg:pt-51'>
          <Footer className='text-black-57' />
        </div>
      </Container>
    </div>
  );
};

export default AssessmentSurveyPage;
