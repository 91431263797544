import React, {useCallback, useLayoutEffect} from 'react';
import {inject, observer} from 'mobx-react';
import Assessment from '../Assessment/Assessment';
import AboutYouQuestion from '../Assessment/AboutYouQuestion';
import Question from '../Assessment/Question';
import gaService from '../../ga.service';
import AssessmentSurveyPage from '../Page/AssessmentSurveyPage';
import assessmentService from '../../model/assessmentService';

const AssessmentPage = ({history, match, pqStore}) => {
  const questionNo = parseInt(match.params.questionNo, 10);
  const totalQuestions = assessmentService.getTotalQuestions(pqStore);
  const labels = ['Not at All', 'A Little', 'Moderately', 'A Lot', 'Extremely'];

  const question = assessmentService.getQuestionByNo(pqStore, questionNo);

  useLayoutEffect(() => {
    const correctQuestionNo = assessmentService.validateQuestionNo(pqStore, questionNo);

    if (correctQuestionNo !== null) {
      return history.replace(`/pq/assessment/${correctQuestionNo}`);
    }
    // the deps are empty intentionally to run the effect only once
    // eslint-disable-next-line
  }, []);

  const handleRandomDebug = useCallback(() => {
    assessmentService.random(pqStore);

    return history.push(
      `/pq/assessment/${assessmentService.getFirstUnansweredQuestionNo(pqStore)}`
    );
  }, [history, pqStore]);

  const handleNext = useCallback(() => {
    const nextNo = assessmentService.getNextNo(pqStore, questionNo);

    if (nextNo !== null) {
      history.push(`/pq/assessment/${nextNo}`);
    } else {
      history.push(`/pq/save`);
    }
  }, [history, pqStore, questionNo]);

  const handlePrevious = useCallback(() => {
    const previousNo = assessmentService.getPreviousNo(pqStore, questionNo);

    if (previousNo !== null) {
      return history.push(`/pq/assessment/${previousNo}`);
    }
  }, [history, pqStore, questionNo]);

  const handleQuestionAnswer = useCallback(
    (answer, goNext) => {
      gaService.sendEvent('PQ Assessment', 'click', `pq_${questionNo}`);

      const {isAboutYou, id} = question;

      assessmentService.addAnswer(pqStore, isAboutYou, id, answer);

      if (goNext) {
        requestAnimationFrame(handleNext);
      }
    },
    [handleNext, pqStore, question, questionNo]
  );

  const handleAboutYouQuestionAnswerNext = useCallback(() => {
    gaService.sendEvent('PQ Assessment', 'click', `pq_${questionNo}`);

    requestAnimationFrame(handleNext);
  }, [questionNo, handleNext]);

  if (!question) {
    return null;
  }

  const header = question.isAboutYou ? 'About You' : '';

  const subheader = question.isAboutYou
    ? 'In order to provide you with relevant insights and future updates, please tell us:'
    : '';

  const hint = question.isAboutYou
    ? ''
    : "In the last 24 hours, what is the greatest degree that you've experienced these feelings?";

  return (
    <AssessmentSurveyPage
      onRandomDebug={process.env.REACT_APP_DEBUG_INFUSIONSOFT === '1' ? handleRandomDebug : null}
      onNext={handleNext}
      onPrevious={handlePrevious}
      canGoNext={assessmentService.canGoNext(pqStore, questionNo)}
      canGoPrevious={assessmentService.canGoPrevious(pqStore, questionNo)}
      questionNo={questionNo}
      totalQuestions={totalQuestions}
    >
      <Assessment
        className='flex-1'
        questionNo={questionNo}
        header={header}
        subheader={subheader}
        hint={hint}
        question={
          question.isAboutYou ? (
            <AboutYouQuestion
              key={question.id}
              no={questionNo}
              total={totalQuestions}
              question={question.question}
              value={assessmentService.getAnswer(pqStore, question)}
              onChange={handleQuestionAnswer}
              onNext={handleAboutYouQuestionAnswerNext}
            />
          ) : (
            <Question
              key={question.id}
              no={questionNo}
              total={totalQuestions}
              question={question.question}
              labels={labels}
              answer={assessmentService.getAnswer(pqStore, question)}
              onAnswer={handleQuestionAnswer}
            />
          )
        }
      />
    </AssessmentSurveyPage>
  );
};

export default inject('pqStore')(observer(AssessmentPage));
