import React from 'react';
import get from 'lodash/get';
import Label from './Label';
import InputHint from './InputHint';
import InputError from './InputError';

const FormikField = ({
  label,
  errors = {},
  touched = {},
  name,
  hint,
  children,
  hiddenFields = [],
  className = ''
}) => {
  if (hiddenFields.includes(name)) {
    return null;
  }

  return (
    <div className={`mt-30 ${className}`}>
      {label ? <Label invalid={get(errors, name) && get(touched, name)}>{label}:</Label> : null}
      {children}
      {hint && (!get(errors, name) || !get(touched, name)) ? (
        <InputHint>{hint}</InputHint>
      ) : (
        <InputError>{get(errors, name) && get(touched, name) && get(errors, name)}</InputError>
      )}
    </div>
  );
};

export default FormikField;
