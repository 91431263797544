import avoider from '../img/saboteurs/avoider.png';
import controller from '../img/saboteurs/controller.png';
// eslint-disable-next-line camelcase
import hyper_achiever from '../img/saboteurs/hyper_achiever.png';
// eslint-disable-next-line camelcase
import hyper_rational from '../img/saboteurs/hyper_rational.png';
// eslint-disable-next-line camelcase
import hyper_vigilant from '../img/saboteurs/hyper_vigilant.png';
import pleaser from '../img/saboteurs/pleaser.png';
import restless from '../img/saboteurs/restless.png';
import stickler from '../img/saboteurs/stickler.png';
import victim from '../img/saboteurs/victim.png';
import judge from '../img/saboteurs/judge.png';

const icons = {
  avoider,
  controller,
  hyper_achiever,
  hyper_rational,
  hyper_vigilant,
  pleaser,
  restless,
  stickler,
  victim,
  judge
};

export default icons;
