import React from 'react';
import AssessmentBlankPage from './Page/AssessmentBlankPage';

const NotFoundPage = () => {
  return (
    <AssessmentBlankPage>
      <div className='flex-1 flex flex-col justify-center'>
        <div className='body text-center'>Page Not Found</div>
      </div>
    </AssessmentBlankPage>
  );
};

export default NotFoundPage;
