import React from 'react';

const Radio = ({name, value, selectedValue, label, onChange}) => (
  <div className='mt-10'>
    <input
      type='radio'
      onChange={onChange}
      id={`${name}_${value}`}
      name={name}
      value={value}
      checked={value === selectedValue}
      className='mr-10'
    />
    <label htmlFor={`${name}_${value}`}>{label}</label>
  </div>
);

export default Radio;
