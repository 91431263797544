import React, {useLayoutEffect, useRef, useState} from 'react';
import Container from '../Page/Container';

const Assessment = ({question, questionNo, header, subheader, hint, className}) => {
  const prevQuestionRef = useRef();
  const prevQuestionNoRef = useRef(questionNo);
  const timeoutRef = useRef(null);
  const [goingBack, setGoingBack] = useState();
  const [prevQuestion, setPrevQuestion] = useState();
  const [animate, setAnimate] = useState(false);
  const [inTransition, setInTransition] = useState(false);

  useLayoutEffect(() => {
    const finishTransition = () => {
      timeoutRef.current = null;
      setAnimate(false);
      setInTransition(false);
      setPrevQuestion(null);
    };

    const startTransition = () => {
      setPrevQuestion(prevQuestionRef.current);
      setInTransition(true);

      requestAnimationFrame(() => {
        setAnimate(true);

        requestAnimationFrame(() => {
          setInTransition(false);
        });
      });
    };

    if (prevQuestionRef.current && prevQuestionRef.current.key !== question.key) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        finishTransition();
      }

      startTransition();

      timeoutRef.current = setTimeout(finishTransition, 500);
    }

    setGoingBack(questionNo < prevQuestionNoRef.current);

    prevQuestionRef.current = question;
    prevQuestionNoRef.current = questionNo;
  }, [question, questionNo]);

  const containerClasses = `absolute top-1/2 transform w-full mt-50 ${
    animate ? 'transition-all duration-500 ' : ''
  }`;

  const positions = {
    top: '-translate-y-2/1 opacity-0',
    middle: '-translate-y-1/2 opacity-100',
    bottom: 'translate-y-full opacity-0'
  };

  return (
    <div className={`relative max-w-810 w-full min-h-560 overflow-hidden ${className}`}>
      <Container
        className={`absolute top-0 z-2 pt-40 lg:pt-50 bg-white w-full transition-all duration-500 ${
          header || subheader ? 'opacity-100' : 'opacity-0'
        }`}
      >
        {header ? <div className='heading-4 lg:heading-3 mb-16'>{header}</div> : null}
        {subheader ? <div className='body max-w-390'>{subheader}</div> : null}
      </Container>
      <Container
        className={`absolute top-0 z-2 pt-40 lg:pt-50 bg-white w-full transition-all duration-500 ${
          hint ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <div className='body'>{hint}</div>
      </Container>

      {prevQuestion ? (
        <Container
          className={`${containerClasses} ${
            inTransition ? positions.middle : goingBack ? positions.bottom : positions.top
          }`}
        >
          {prevQuestion}
        </Container>
      ) : null}
      <Container
        className={`${containerClasses} ${
          inTransition ? (goingBack ? positions.top : positions.bottom) : positions.middle
        }`}
      >
        {question}
      </Container>
    </div>
  );
};

export default Assessment;
