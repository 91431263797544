import React from 'react';
import Link from '../Link';
import AssessmentTextPage from '../Page/AssessmentTextPage';
import clientTypes from '../../const/clientTypes';
import AssessmentTwoBlockPage from '../Page/AssessmentTwoBlockPage';
import B2BLeaderContent from './B2BLeaderContent';
import B2BIndividualContent from './B2BIndividualContent';
import B2CContent from './B2CContent';

const LeftBlockContent = ({clientType, clientName}) => {
  switch (clientType) {
    case clientTypes.B2B_LEADER:
      return <B2BLeaderContent {...{clientType, clientName}} />;
    case clientTypes.B2B_INDIVIDUAL:
      return <B2BIndividualContent {...{clientType, clientName}} />;
    case clientTypes.B2C:
      return <B2CContent {...{clientType, clientName}} />;
    default:
      return null;
  }
};

const AssessmentsResultsContent = ({updateLink, email, clientType, tempLink}) => (
  <>
    <h3 className='heading-5 lg:heading-3'>Assessments Results in Email</h3>
    <div className='body lg:pt-40'>
      <p className='heading-7'>
        The results will be sent to: <strong>{email}</strong>
      </p>
      <p className='mt-28'>Look for an email from Shirzad | Positive Intelligence</p>
      <p className='mt-28'>Click on the link in the email to see your assessment results</p>
      <p className='mt-28'>
        If you don’t find it, check your spam folder, or double-check that you typed your email
        address correctly.
      </p>
      <p className='mt-28'>
        In some cases it can take up to an hour for email to appear in your inbox depending on your
        location and email server settings.
      </p>
      <p className='mt-28'>
        A wrong email?{' '}
        <Link
          href={updateLink}
          target='_blank'
          tabIndex={1}
          className='focus:outline-blue rounded-6'
        >
          {' '}
          Update here
        </Link>
      </p>
      {process.env.REACT_APP_DEBUG_INFUSIONSOFT === '1' ? (
        <p>
          DEBUG:
          <br />
          Client type: <b>{clientType}</b>
          <br />
          <Link className='focus:outline-blue rounded-6' href={tempLink} tabIndex={0}>
            The results link
          </Link>
        </p>
      ) : null}
    </div>
  </>
);

const SavedResult = ({
  updateLink,
  tempLink,
  clientType,
  clientName,
  email,
  history,
  withLeftBlockContent
}) =>
  clientType === (clientTypes.COACH || clientTypes.EXISTING) || !withLeftBlockContent ? (
    <AssessmentTextPage {...{clientType, clientName}}>
      <AssessmentsResultsContent {...{history, updateLink, tempLink, email}} />
    </AssessmentTextPage>
  ) : (
    <AssessmentTwoBlockPage leftBlock={<LeftBlockContent {...{clientType, clientName}} />}>
      <div className='flex-1 flex flex-col justify-center items-center'>
        <div className='px-30 lg:px-48 flex-initial max-w-810 sm:py-8'>
          <AssessmentsResultsContent {...{history, updateLink, tempLink, email}} />
        </div>
      </div>
    </AssessmentTwoBlockPage>
  );

export default SavedResult;
