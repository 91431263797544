import React from 'react';
import Header from './Header';

const AssessmentBlankPage = ({children}) => (
  <div className='flex flex-col min-h-full'>
    <Header />
    {children}
  </div>
);

export default AssessmentBlankPage;
