import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import SignUpForm from '../SignUpForm';
import gaService from '../../ga.service';
import AssessmentBlankPage from '../Page/AssessmentBlankPage';
import assessmentService from '../../model/assessmentService';
import Loading from '../Loading';

class SignUpPage extends Component {
  UNSAFE_componentWillMount() {
    const {
      history,
      pqStore,
      match: {
        params: {resultId}
      }
    } = this.props;

    if (resultId) {
      pqStore.loadAssessmentResults(resultId).then(() => {
        pqStore.toBeUpdated();
      });
    } else {
      const unansweredQuestionNo = assessmentService.getFirstUnansweredQuestionNo(pqStore, true);
      if (unansweredQuestionNo !== null) {
        return history.replace(`/pq/assessment/${unansweredQuestionNo}`);
      }
    }
  }

  handleSignUp = () => {
    const {history, pqStore} = this.props;

    gaService.sendEvent('PQ Assessment', 'click', 'pq_result');

    return pqStore.saveAssessmentResultsData().then(() => {
      const {result} = pqStore;

      if (result.data.confirmed) {
        return history.push(`/pq/saved/${result.id}`);
      } else {
        return history.push(`/pq/confirm/${result.id}`);
      }
    });
  };

  render() {
    const {pqStore} = this.props;

    return (
      <AssessmentBlankPage>
        {pqStore.result.pending ? <Loading fixed /> : null}
        <SignUpForm data={pqStore.assessmentData} onSignUp={this.handleSignUp} checkRestricted />
      </AssessmentBlankPage>
    );
  }
}

export default inject('pqStore')(observer(SignUpPage));
