import React from 'react';

const ProgressBar = ({position, className}) => (
  <div className={`bg-black-5 w-100 h-8 rounded-full relative ${className}`}>
    <div
      className='bg-orange w-100 h-8 rounded-full absolute top-0 left-0 transition-width'
      style={{width: `${position * 100}%`}}
    />
  </div>
);

export default ProgressBar;
