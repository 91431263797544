import React from 'react';
import PQLogo from '../PQLogo';
import Container from './Container';
import Footer from './Footer';

const AssessmentInfoPage = ({image, title, subtitle, wide, text, button}) => (
  <div className='flex flex-col lg:flex-row min-h-full'>
    <div className='flex-initial w-full lg:w-1/3 lg:min-w-435 flex flex-col bg-orange'>
      <Container className='flex-initial'>
        <div className='pt-30 lg:pt-50'>
          <PQLogo />
        </div>
      </Container>
      <div className='flex-auto flex justify-center items-center'>
        <div className='flex-initial pb-54 pt-36 lg:pb-0 lg:pt-0'>{image}</div>
      </div>
      <Container className='flex-initial hidden lg:block lg:py-47'>
        <Footer />
      </Container>
    </div>
    <Container className='flex-1 lg:flex-initial w-full lg:w-2/3 flex flex-col lg:flex-row lg:justify-start lg:items-center bg-white'>
      <div className='flex-1 lg:flex-initial lg:max-w-520 py-72'>
        <h3 className='heading-4 lg:heading-3 mb-22 lg:mb-39'>{title}</h3>
        {subtitle ? <div className='body-sm mb-12'>{subtitle}</div> : null}
        <div className={`body mb-22 lg:mb-40 ${wide ? '' : 'lg:max-w-400'}`}>{text}</div>
        {button || null}
      </div>
      <div className='flex-initial lg:hidden pb-28'>
        <Footer className='text-black-57' />
      </div>
    </Container>
  </div>
);

export default AssessmentInfoPage;
