import React, {Component} from 'react';
import Button from '../Page/Button';
import AssessmentTextPage from '../Page/AssessmentTextPage';

export default class AssessmentResultScorePage extends Component {
  render() {
    const {history} = this.props;

    const handleMoveToSaboteurPage = () => {
      history.push(`/saboteur`);
    };

    return (
      <AssessmentTextPage>
        <h3 className='heading-4 lg:heading-3'>How to Boost PQ</h3>
        <div className='body pt-21 lg:pt-40'>
          <p>
            Based on research with 500,000 individuals, the fastest way to improve your PQ is to{' '}
            <strong>identify which “Saboteurs” in your head generate your negative emotions</strong>{' '}
            such as stress, self-doubt, anger, fear, or shame.
          </p>
          <p>
            The <strong>FREE 5-minute Saboteur Assessment</strong> will expose your top Saboteurs
            and the lies and tricks they use against you. We’ll then show you exactly how to weaken
            them, so you can boost your PQ, perform better, improve your relationships, and be
            happier and less stressed.
          </p>
        </div>
        <Button
          label='Take the Saboteur Assessment (Free)'
          tabIndex={0}
          className='mt-30'
          onClick={handleMoveToSaboteurPage}
          onEnter={handleMoveToSaboteurPage}
        />
      </AssessmentTextPage>
    );
  }
}
