import React, {useCallback, useState} from 'react';
import InputError from './InputError';
import InputHint from './InputHint';

const Input = React.forwardRef((props, ref) => {
  const {
    className = '',
    labelClassName = '',
    label,
    error,
    hint,
    component = 'input',
    onBlur,
    onFocus,
    onMouseDown,
    ...rest
  } = props;

  const [focused, setFocused] = useState(false);
  const [outlineRemoved, setOutlineRemoved] = useState(false);

  const handleFocus = useCallback(
    (e) => {
      setFocused(true);

      onFocus && onFocus(e);
    },
    [setFocused, onFocus]
  );

  const handleBlur = useCallback(
    (e) => {
      setFocused(false);
      setOutlineRemoved(false);

      onBlur && onBlur(e);
    },
    [setFocused, setOutlineRemoved, onBlur]
  );

  const handleMouseDown = useCallback(
    (e) => {
      setOutlineRemoved(true);

      onMouseDown && onMouseDown(e);
    },
    [setOutlineRemoved, onMouseDown]
  );

  const Component = component;
  const isFloating = focused || !!rest.value || rest.placeholder;

  return (
    <>
      <label
        className={`block w-full relative ${className}`}
        htmlFor={rest.id}
        onMouseDown={handleMouseDown}
      >
        <div
          className={`absolute text-black-57 left-0 z-0 transition-all duration-200 ${labelClassName} ${
            isFloating ? 'xsmall-type -top-4' : 'small-type top-7'
          } ${!isFloating && !rest.disabled ? 'cursor-text' : ''}`}
        >
          {label}
        </div>
        <Component
          className={`w-full z-1 input-text rounded-6 py-7 small-type border-black-lines border-b bg-white placeholder-black-57
           ${
             outlineRemoved
               ? 'outline-none'
               : label
               ? 'focus:outline-blueExtended pt-15'
               : 'focus:outline-blue'
           }
           ${outlineRemoved && label ? 'pt-15' : ''}
            `}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onMouseDown={handleMouseDown}
          ref={ref}
          {...rest}
        />
      </label>
      {error ? <InputError>{error}</InputError> : null}
      {hint && !error ? <InputHint>{hint}</InputHint> : null}
    </>
  );
});

export default Input;
