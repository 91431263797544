import React from 'react';
import earnedTrustImg from '../../img/shareContentImages/earnedTrust.png';
import healthyConflictImg from '../../img/shareContentImages/healthyConflict.png';
import ContentContainer from './ContentContainer';

const B2BLeaderContent = ({clientName, clientType}) => (
  <ContentContainer
    className='bg-green min-w-420'
    title='Boost employee performance with PQ Program'
    description='Move beyond Saboteurs and reach new heights as shown by our clients’ teams'
    clientName={clientName}
    clientType={clientType}
    refId='a-bld'
    withoutShareButton
  >
    <div className='flex pt-24 sm:pt-40 w-full justify-center'>
      <div className='flex w-161 sm:w-141 h-154 flex-col bg-green-light rounded-lg justify-around p-16'>
        <div className='w-80 h-80 self-center'>
          <img className='w-full h-full' src={earnedTrustImg} alt='' />
        </div>
        <div className='heading-7 self-center text-center'>92% Increase Collaboration</div>
      </div>
      <div className='flex w-161 sm:w-141 h-154 flex-col bg-green rounded-lg justify-around ml-8 p-16 border-2'>
        <div className='w-80 h-80 self-center'>
          <img className='w-full h-full' src={healthyConflictImg} alt='' />
        </div>
        <div className='heading-7 self-center w-150 text-center'>84% Manage Conflict Better</div>
      </div>
    </div>
  </ContentContainer>
);

export default B2BLeaderContent;
