import React from 'react';
import vortex from '../../img/PQVortex.png';

const Score = ({score, style = {}}) => {
  return (
    <div
      className='small-type w-40 h-40 pt-3 pl-1 bg-white border-2 rounded-full absolute flex items-center justify-center left-1/2 transform -translate-x-1/2 translate-y-1/2'
      style={{bottom: score + '%', ...style}}
    >
      {score}
    </div>
  );
};

const Label = ({text, score, offset}) => {
  return (
    <div
      className='absolute w-full flex items-center transform translate-y-1/2'
      style={{bottom: score + '%'}}
    >
      <div className='flex-initial mr-7'>{text}</div>
      <div className='flex-initial border-b border-dashed' style={{width: offset + '%'}} />
    </div>
  );
};

const Vortex = ({score, withPoints}) => {
  const nearTippingPoint = score > 65 && score < 85;
  return (
    <div className='relative h-300 lg:h-415'>
      {withPoints ? (
        <>
          <div className='absolute h-full w-full -left-40 xsmall-type'>
            <Label text='Thriving' score={87} offset={27} />
            <Label text='Tipping Point' score={75} offset={nearTippingPoint ? 11 : 24} />
            <Label text='Surviving' score={31} offset={20} />
          </div>
          <Score score={75} style={nearTippingPoint ? {left: '35%'} : {}} />
          <div className='absolute h-full w-full left-20 xsmall-type'>
            <div
              className='absolute w-full flex items-center justify-end transform translate-y-1/2'
              style={{bottom: score + '%'}}
            >
              <div className='flex-initial border-b border-dashed' style={{width: '37%'}} />
              <div className='flex-initial mr-7'>You</div>
            </div>
          </div>
        </>
      ) : null}
      <Score score={score} />
      <img alt='vortex' className='h-full' src={vortex} />
    </div>
  );
};

export default Vortex;
