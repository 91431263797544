import React from 'react';

const ProgressBar = ({position, className, value}) => (
  <div className={`bg-black-5 w-full h-32 lg:h-28 rounded-full relative ${className}`}>
    <div
      className='bg-orange w-100 h-32 lg:h-28 rounded-full absolute top-0 left-0 text-right transition-width'
      style={{width: `${position * 100}%`}}
    >
      <div className='mr-12 mt-6 font-matter font-semibold leading-4 text-22'>{value}</div>
    </div>
  </div>
);

export default ProgressBar;
