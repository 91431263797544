import React from 'react';
import Header from './Header';

const AssessmentTwoBlockPage = ({leftBlock, children}) => (
  <div className={`min-h-full flex ${leftBlock ? 'flex-col lg:flex-row' : 'flex-row sm:flex-col'}`}>
    {leftBlock}
    <div className={`py-30 sm:py-0 flex flex-col ${leftBlock ? '' : 'lg:contents'}`}>
      <Header className={`${leftBlock ? 'hidden' : ''} sm:block`} />
      {children}
    </div>
  </div>
);

export default AssessmentTwoBlockPage;
