import React from 'react';
import get from 'lodash/get';
import compact from 'lodash/compact';
import toLower from 'lodash/toLower';
import {FieldArray} from 'formik';
import Input from '../Form/Input';
import Button from '../Page/Button';
import crossIcon from '../../img/cross.svg';
import redCrossIcon from '../../img/cross_red.svg';
import checkIcon from '../../img/chk1.png';
import FormikField from '../Form/FormikField';
import emailRegex from '../../const/emailRegex';

const ApplicationPodForm = ({limit, values, errors, touched, handleChange, handleBlur}) => {
  return (
    <>
      <FormikField name='title' {...{errors, touched}}>
        <Input
          type='text'
          label='Pod Name*'
          name='title'
          placeholder='The Happy Podders'
          className='w-full'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.title}
        />
      </FormikField>
      <div className='flex flex-col lg:flex-row'>
        <div className='flex-1 lg:mr-27'>
          <FormikField name='email' {...{errors, touched}}>
            <Input
              type='email'
              label='Your Email*'
              name='email'
              className='w-full'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              disabled
            />
          </FormikField>
        </div>
        <div className='flex-1 lg:mx-13'>
          <FormikField name='first_name' {...{errors, touched}}>
            <Input
              label='Your First Name*'
              name='first_name'
              className='w-full'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.first_name}
            />
          </FormikField>
        </div>
        <div className='flex-1 lg:ml-27'>
          <FormikField name='last_name' {...{errors, touched}}>
            <Input
              label='Your Last Name*'
              name='last_name'
              className='w-full'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.last_name}
            />
          </FormikField>
        </div>
      </div>
      <div className='body mt-30'>
        Fill in the email addresses and names of the people you’d like to invite to your Pod (up to{' '}
        {limit} total)
      </div>
      <FieldArray
        name='team_list'
        render={({push, remove}) => (
          <div>
            {values.team_list.map((member, index) => (
              <div key={index}>
                <div className='flex flex-col lg:flex-row'>
                  <div className='flex-1 lg:mr-27'>
                    <FormikField name={`team_list.${index}.email`} {...{errors, touched}}>
                      <Input
                        type='text'
                        name={`team_list.${index}.email`}
                        label={`Email #${index + 1}*`}
                        className='w-full'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={member.email}
                      />
                    </FormikField>
                  </div>
                  <div className='flex-1 lg:mx-13'>
                    <FormikField name={`team_list.${index}.first_name`} {...{errors, touched}}>
                      <Input
                        type='text'
                        label='First Name*'
                        name={`team_list.${index}.first_name`}
                        className='w-full'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={member.first_name}
                      />
                    </FormikField>
                  </div>
                  <div className='flex-1 lg:ml-27'>
                    <FormikField name={`team_list.${index}.last_name`} {...{errors, touched}}>
                      <Input
                        type='text'
                        label='Last Name*'
                        name={`team_list.${index}.last_name`}
                        className='w-full'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={member.last_name}
                      />
                    </FormikField>
                  </div>
                  <div className='flex-initial flex flex-row items-center'>
                    {index > 0 ? (
                      <div
                        className='cursor-pointer w-30 h-30 mt-18 ml-10 lg:mt-0'
                        onClick={() => remove(index)}
                      >
                        <img src={crossIcon} alt='' />
                      </div>
                    ) : null}
                    {!get(errors, `team_list.${index}.email`) && member.email ? (
                      <div className='w-30 h-30 mt-18 ml-10 lg:mt-0'>
                        <img src={checkIcon} alt='' />
                      </div>
                    ) : null}
                    {get(errors, `team_list.${index}.email`) &&
                    get(touched, `team_list.${index}.email`) &&
                    member.email ? (
                      <div className='w-30 h-30 mt-18 ml-10 lg:mt-0'>
                        <img src={redCrossIcon} alt='' />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ))}
            {values.team_list.length < limit - 1 ? (
              <Button
                label='Add More Emails'
                className='mt-40 mb-25'
                onClick={() => push({email: '', first_name: '', last_name: ''})}
              />
            ) : null}
          </div>
        )}
      />
    </>
  );
};

const checkRequiredMemberFields = (member) => {
  const errors = {};

  ['email', 'first_name', 'last_name'].forEach((field) => {
    if (!member[field]) {
      errors[field] = 'This field is required';
    }
  });

  if (member.email && !emailRegex.test(member.email)) {
    errors.email = 'The email address is not valid';
  }

  return Object.keys(errors).length ? errors : undefined;
};

export const validateForm = async (values, checkEmail, checkTitle) => {
  const errors = checkRequiredMemberFields(values) || {};
  const teamListErrors = [];

  if (!values.title) {
    errors.title = 'This field is required';
  } else {
    const titleError = await checkTitle(values.email);

    if (titleError) {
      errors.title = titleError;
    }
  }

  for (let i = 0; i < values.team_list.length; i++) {
    const member = values.team_list[i];
    teamListErrors[i] = checkRequiredMemberFields(member);

    if (values.team_list.filter(({email}) => toLower(member.email) === toLower(email)).length > 1) {
      teamListErrors[i] = teamListErrors[i] || {};
      teamListErrors[i].email = 'All Pod email addresses must be unique';
    }

    if (!teamListErrors[i] || !teamListErrors[i].email) {
      const emailError = await checkEmail(member.email);

      if (emailError) {
        teamListErrors[i] = teamListErrors[i] || {};
        teamListErrors[i].email = emailError;
      }
    }
  }

  if (compact(teamListErrors).length) {
    errors.team_list = teamListErrors;
  }

  if (Object.keys(errors).length > 0) {
    errors.form = 'Please fix validation errors';
  }

  return errors;
};

export default ApplicationPodForm;
