import React, {useState} from 'react';
import queryString from 'query-string';
import {useLocation} from 'react-router-dom';
import Loading from './Loading';

const OptInPage = () => {
  const [pending, setPending] = useState(false);
  const query = queryString.parse(useLocation().search);
  const email = query.email;

  const handleSubmit = () => setPending(true);

  if (!email) {
    return <div>No Email</div>;
  }

  return (
    <div className='flex flex-col gap-12 min-h-full items-center justify-center p-16'>
      {pending && <Loading fixed />}
      <div className='body max-w-460 text-center'>
        To proceed with your PQ Coach Grant Application, please confirm you opt-in for PQ messaging.
      </div>
      <form
        acceptCharset='UTF-8'
        action='https://mk232.infusionsoft.com/app/form/process/0eb262dd80a30001091c584eaecace5a'
        id='inf_form_0eb262dd80a30001091c584eaecace5a'
        method='POST'
        onSubmit={handleSubmit}
      >
        <input name='inf_form_xid' type='hidden' value='0eb262dd80a30001091c584eaecace5a' />
        <input
          name='inf_form_name'
          type='hidden'
          value='Opt back in Webform&#a;100x Application Page'
        />
        <input name='infusionsoft_version' type='hidden' value='1.70.0.541493' />

        <input
          id='inf_field_FirstName'
          name='inf_field_FirstName'
          placeholder='First Name'
          type='hidden'
        />
        <input
          id='inf_field_LastName'
          name='inf_field_LastName'
          placeholder='Last Name'
          type='hidden'
        />
        <input
          id='inf_field_Email'
          name='inf_field_Email'
          placeholder='Email *'
          type='hidden'
          value={email}
        />
        <button
          className='infusion-recaptcha px-47 py-11 bg-black hover:bg-blackHover-background small-type text-white inline-block rounded-full text-center'
          id='recaptcha_0eb262dd80a30001091c584eaecace5a'
          type='submit'
        >
          Opt In
        </button>
      </form>
      <script
        type='text/javascript'
        src='https://mk232.infusionsoft.app/app/webTracking/getTrackingCode'
      />
      <script
        type='text/javascript'
        src='https://mk232.infusionsoft.com/resources/external/recaptcha/production/recaptcha.js?b=1.70.0.541493'
      />
      <script
        src='https://www.google.com/recaptcha/api.js?onload=onloadInfusionRecaptchaCallback&render=explicit'
        async='async'
        defer='defer'
      />
      <script
        type='text/javascript'
        src='https://mk232.infusionsoft.com/app/timezone/timezoneInputJs?xid=0eb262dd80a30001091c584eaecace5a'
      />
      <script
        type='text/javascript'
        src='https://mk232.infusionsoft.com/js/jquery/jquery-3.3.1.js'
      />
      <script
        type='text/javascript'
        src='https://mk232.infusionsoft.app/app/webform/overwriteRefererJs'
      />
    </div>
  );
};

export default OptInPage;
