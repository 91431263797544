import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import LoadingPage from '../LoadingPage';
import Button from '../Page/Button';
import ShareResults from './ShareResults';
import AssessmentInfoPage from '../Page/AssessmentInfoPage';

class LearnMorePage extends Component {
  state = {
    redirecting: false,
    sabVideoUnmuted: false
  };

  async UNSAFE_componentWillMount() {
    const {
      sabStore,
      match: {
        params: {resultId}
      }
    } = this.props;

    await sabStore.loadAssessmentResults(resultId);
  }

  handleGoToWebsiteClick = () => {
    this.setState({redirecting: true});
    window.location.href = 'https://www.positiveintelligence.com';
  };

  render() {
    const {redirecting} = this.state;
    const {pending} = this.props.sabStore.result;

    if (pending || redirecting) {
      return <LoadingPage />;
    }

    return (
      <AssessmentInfoPage
        image={<div className='img-sab-results-more' />}
        title='Stay Tuned'
        text={
          <>
            Congratulations on learning to identify your Saboteurs and expose their lies that
            sabotage your performance and happiness.
            <br />
            Shirzad Chamine, the Stanford lecturer leading this research, will keep you updated with
            new insights as they become available.
            <br />
            <ShareResults />
          </>
        }
        button={
          <Button
            tabIndex={0}
            onEnter={this.handleGoToWebsiteClick}
            onClick={this.handleGoToWebsiteClick}
            label='Back to website'
          />
        }
      />
    );
  }
}

export default inject('sabStore')(observer(LearnMorePage));
