import React from 'react';
import {usePreventFocusOnClick} from '../utils/usePreventFocusOnClick';

const Link = React.forwardRef(({className, ...props}, ref) => {
  const handlePreventFocusOnClick = usePreventFocusOnClick();

  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return (
    <a
      ref={ref}
      {...props}
      className={`small-type cursor-pointer ${className}`}
      onMouseDown={handlePreventFocusOnClick}
    />
  );
});

export default Link;
