import React, {Component} from 'react';
import QuestionButton from './QuestionButton';

export default class Question extends Component {
  handleAnswer = (answer) => {
    this.props.onAnswer(answer, true);
  };

  render() {
    const {question, labels, no, answer, total} = this.props;

    return (
      <div className='relative overflow-hidden pb-30 lg:pb-83 pt-13 lg:pt-0'>
        <div
          className='small-type'
          tabIndex={1}
          role='presentation'
          aria-label={`Positive Intelligence Saboteur Assessment. This is ${no} of ${total} questions`}
        >
          <span aria-hidden>
            {no} / {total}
          </span>
        </div>
        <div className='body-lg pt-20'>
          <label htmlFor={`answers-${no}`} tabIndex={2}>
            {question.text}
          </label>
        </div>
        <ul
          className='flex flex-row items-center pt-67 pl-3 pr-3'
          id={`answers-${no}`}
          aria-label='Answers'
        >
          <li
            className='flex-initial'
            id={`stronglyDisagree-${no}`}
            role='option'
            aria-selected={false}
            aria-label='Strongly Disagree'
          >
            <QuestionButton onAnswer={this.handleAnswer} answer={0} active={answer === 0} />
          </li>
          <div className='flex-1' role='none'>
            <div className='border border-dashed' />
          </div>
          <li
            className='flex-initial'
            id={`disagree-${no}`}
            role='option'
            aria-selected={false}
            aria-label='Disagree'
          >
            <QuestionButton onAnswer={this.handleAnswer} answer={1} active={answer === 1} />
          </li>
          <div className='flex-1' role='none'>
            <div className='border border-dashed' />
          </div>
          <li
            className='flex-initial'
            id={`neutral-${no}`}
            role='option'
            aria-selected={false}
            aria-label='Neutral'
          >
            <QuestionButton onAnswer={this.handleAnswer} answer={2} active={answer === 2} />
          </li>
          <div className='flex-1' role='none'>
            <div className='border border-dashed' />
          </div>
          <li
            className='flex-initial'
            id={`agree-${no}`}
            role='option'
            aria-selected={false}
            aria-label='Agree'
          >
            <QuestionButton onAnswer={this.handleAnswer} answer={3} active={answer === 3} />
          </li>
          <div className='flex-1' role='none'>
            <div className='border border-dashed' />
          </div>
          <li
            className='flex-initial'
            id={`stronglyAgree-${no}`}
            role='option'
            aria-selected={false}
            aria-label='Strongly Agree'
          >
            <QuestionButton onAnswer={this.handleAnswer} answer={4} active={answer === 4} />
          </li>
        </ul>
        <div className='flex flex-row justify-center pt-15 lg:pt-8' aria-hidden>
          {labels.map((label, i) => renderLabel(label, i, labels.length))}
        </div>
      </div>
    );
  }
}

const renderLabel = (label, i, total) => {
  const isFirst = i === 0;
  const isLast = i === total - 1;
  const align = isFirst ? 'left' : isLast ? 'right' : 'center';
  return (
    <React.Fragment key={`${i} - ${label}`}>
      <div className={`flex-initial text-${align} xsmall-type lg:small-type whitespace-pre`}>
        {label}
      </div>
      {isLast ? null : <div className='flex-1' />}
    </React.Fragment>
  );
};
