import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import ConfirmResultsForm from '../ConfirmResultsForm';
import LoadingPage from '../LoadingPage';

class ConfirmPage extends Component {
  state = {
    redirecting: false
  };

  UNSAFE_componentWillMount() {
    const {resultId} = this.props.match.params;

    this.props.sabStore.loadAssessmentResults(resultId);
  }

  handleConfirm = async (code) => {
    const {history, sabStore} = this.props;
    const {resultId} = this.props.match.params;

    return sabStore.confirmAssessmentResult(resultId, code).then(() => {
      return history.replace(`/saboteur/saved/${resultId}`);
    });
  };

  render() {
    const {sabStore, history} = this.props;
    const {resultId} = this.props.match.params;
    const {redirecting} = this.state;
    const {pending} = sabStore.result;

    if (pending || redirecting) {
      return <LoadingPage />;
    }

    return (
      <ConfirmResultsForm
        history={history}
        id={resultId}
        onConfirm={this.handleConfirm}
        email={sabStore.result.data.email}
        code={
          process.env.REACT_APP_DEBUG_INFUSIONSOFT === '1'
            ? sabStore.result.data.temp_confirmation_code
            : ''
        }
        updateLink={`/saboteur/update/${resultId}`}
      />
    );
  }
}

export default inject('sabStore')(observer(ConfirmPage));
