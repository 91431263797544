import React from 'react';
import ProgressBar from './ProgressBar';

const Progress = ({current, total, className = ''}) => {
  const currentProgress = total > 0 ? Math.round(((current - 1) / total) * 100) : 0;

  return (
    <div className={`flex w-full items-center ${className}`}>
      <div
        className='flex-initial small-type w-48'
        aria-label='Assessment Progress'
        aria-valuemin={0}
        aria-valuemax={100}
        aria-valuenow={currentProgress}
      >
        {currentProgress}%
      </div>
      <ProgressBar className='flex-1' position={(current - 1) / total} />
    </div>
  );
};

export default Progress;
