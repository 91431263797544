import React from 'react';
import Container from './Container';
import AssessmentBlankPage from './AssessmentBlankPage';

const AssessmentTextPage = ({children}) => (
  <AssessmentBlankPage>
    <div className='flex-1 flex flex-col justify-center items-center'>
      <Container className='flex-initial max-w-810 py-20 lg:py-40'>{children}</Container>
    </div>
  </AssessmentBlankPage>
);

export default AssessmentTextPage;
