import ShareViaEmailButton from '../../ShareViaEmailButton';
import ShareViaCopyButton from '../../ShareViaCopyButton';
import Link from '../../Link';
import React from 'react';
import clientTypes from '../../../const/clientTypes';
import TwitterIcon from '../../../img/social/Twitter.svg';
import LinkedinIcon from '../../../img/social/Linkedin.svg';
import FacebookIcon from '../../../img/social/Facebook.svg';
import gaService from '../../../ga.service';

const B2BIndividualShareText =
  'Hi, I just learned about this mental fitness program for teams, and I think it can help us strengthen our team trust, collaboration, and performance.';

const B2CShareText =
  "Hi, I just took this assessment about how my brain works, and I think you'll like it too.";

const getB2BIndividualEmailContent = (linkToShare) =>
  `${B2BIndividualShareText} Here’s a link where you can learn more: ${linkToShare}`;

const getB2CEmailContent = (linkToShare) => `${B2CShareText} Here's a link: ${linkToShare}`;

const ShareButtonMenu = ({clientName, clientType, refId}) => {
  const getLinkToShare = (shareType) =>
    `${
      clientType === clientTypes.B2B_INDIVIDUAL
        ? process.env.REACT_APP_PQ_FOR_ORGANIZATIONS_URL
        : process.env.REACT_APP_SA_URL
    }?ref=${refId}-${shareType}`;

  const emailContent =
    clientType === clientTypes.B2B_INDIVIDUAL
      ? getB2BIndividualEmailContent(getLinkToShare('im'))
      : getB2CEmailContent(getLinkToShare('im'));

  const shareText = clientTypes.B2B_INDIVIDUAL ? B2BIndividualShareText : B2CShareText;

  const handleTrackMenuItemClick = (eventLabel) =>
    gaService.sendEvent('Saboteur Assessment', 'click', eventLabel);

  return (
    <ul className='absolute bottom-full left-0 bg-white mb-16 rounded-lg shadow-menu w-full py-12'>
      <li className='hover:bg-grey-background'>
        <ShareViaEmailButton
          text='Email'
          subject={`Saboteur Assessment Results from ${clientName}`}
          body={emailContent}
          className='small-type-medium flex w-full pl-12 h-40 items-center'
          iconClassname='w-32 h-32'
          onClick={() => handleTrackMenuItemClick('share_email')}
        />
      </li>
      <li className='hover:bg-grey-background'>
        <ShareViaCopyButton
          tabIndex={0}
          text='Copy link'
          textToCopy={getLinkToShare('cp')}
          className='small-type-medium flex w-full pl-12 h-40 items-center'
          iconClassname='w-32 h-32 mr-8'
          onClick={() => handleTrackMenuItemClick('share_copy_link')}
        />
      </li>
      <li className='hover:bg-grey-background'>
        <Link
          target='_blank'
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
            getLinkToShare('li')
          )}`}
          className='small-type-medium focus:outline-blue rounded-6 flex w-full pl-12 py-5 h-40 items-center'
          onClick={() => handleTrackMenuItemClick('share_linkedin')}
        >
          <img alt='linkedin icon' className='inline-block mr-8' src={LinkedinIcon} />
          Linkedin
        </Link>
      </li>
      <li className='hover:bg-grey-background'>
        <Link
          target='_blank'
          href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
            getLinkToShare('tw')
          )}&text=${shareText}`}
          className='small-type-medium focus:outline-blue rounded-6 flex w-full pl-12 h-40 items-center'
          onClick={() => handleTrackMenuItemClick('share_twitter')}
        >
          <img alt='twitter icon' className='inline-block mr-8' src={TwitterIcon} />
          Twitter
        </Link>
      </li>
      <li className='hover:bg-grey-background'>
        <Link
          target='_blank'
          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            getLinkToShare('fb')
          )}`}
          className='small-type-medium focus:outline-blue rounded-6 flex w-full pl-12 h-40 items-center'
          onClick={() => handleTrackMenuItemClick('share_facebook')}
        >
          <img alt='facebook icon' className='inline-block mr-8 w-32 h-32' src={FacebookIcon} />
          Facebook
        </Link>
      </li>
    </ul>
  );
};

export default ShareButtonMenu;
