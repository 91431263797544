import React from 'react';
import Button from '../Page/Button';
import gaService from '../../ga.service';
import AssessmentInfoPage from '../Page/AssessmentInfoPage';

const OverviewPage = ({history}) => {
  const handleMoveToDefinitionPage = () => {
    gaService.sendEvent('PQ Assessment', 'click', 'pq_intro');
    history.push(`/pq/definition`);
  };

  return (
    <AssessmentInfoPage
      image={<div className='img-pq-overview' />}
      title='Overview'
      text={
        <>
          <p>PQ = Positive Intelligence Quotient</p>
          <p>Based on Shirzad Chamine’s New York Times bestselling book and Stanford lectures.</p>
          <p>Researched with 500,000 participants from 50 countries.</p>
        </>
      }
      button={
        <Button
          label='Next'
          tabIndex={0}
          onClick={handleMoveToDefinitionPage}
          onEnter={handleMoveToDefinitionPage}
        />
      }
    />
  );
};

export default OverviewPage;
