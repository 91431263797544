import Container from './Container';
import PQLogo from '../PQLogo';
import React from 'react';

const Header = ({className = ''}) => (
  <Container className={`flex-initial py-30 lg:py-50 ${className}`}>
    <PQLogo />
  </Container>
);

export default Header;
