import React, {useCallback} from 'react';
import {usePreventFocusOnClick} from '../../utils/usePreventFocusOnClick';

const Button = ({label, href, target, disabled, outlined, onEnter, className = '', onClick}) => {
  const handleClick = useCallback(
    (...args) => {
      if (!disabled) {
        onClick && onClick(...args);
      }
    },
    [disabled, onClick]
  );

  const handlePreventDefaultOnClick = usePreventFocusOnClick();

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter' && !disabled) {
        onEnter();
      }
    },
    [onEnter, disabled]
  );

  return (
    <a
      href={href}
      target={target}
      className={`${
        disabled
          ? 'bg-black-30 cursor-default'
          : outlined
          ? 'bg-transparent text-black border-2'
          : 'bg-black hover:bg-black-30'
      } cursor-pointer focus:outline-blue text-white small-type text-center inline-block px-47 py-11 rounded-full ${className}
      `}
      onMouseDown={handlePreventDefaultOnClick}
      onKeyDown={handleKeyDown}
      onClick={handleClick}
    >
      {label}
    </a>
  );
};

export default Button;
