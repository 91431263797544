import React from 'react';
import Button from '../Page/Button';
import gaService from '../../ga.service';
import AssessmentInfoPage from '../Page/AssessmentInfoPage';

const InstructionsPage = ({history}) => {
  const handleMoveToAssessmentPage = () => {
    gaService.sendEvent('PQ Assessment', 'click', 'pq_begin');
    history.push(`/pq/assessment/1`);
  };

  return (
    <AssessmentInfoPage
      image={<div className='img-assessment-instructions' />}
      title='Assessment Instructions'
      text={
        <>
          <p>
            We’re looking for emotions you’ve experienced more than a few seconds{' '}
            <strong>in the last 24 hours.</strong>
            <br />
            Move quickly and go with the first response that comes to your mind.
          </p>
        </>
      }
      button={
        <Button
          label='Next'
          tabIndex={0}
          onClick={handleMoveToAssessmentPage}
          onEnter={handleMoveToAssessmentPage}
        />
      }
    />
  );
};

export default InstructionsPage;
